import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-finished-task-detail',
  standalone: true,
  imports: [CommonModule, MaterialModule, MatDatepickerModule, MatSelectModule],
  templateUrl: './finished-task-detail.component.html',
  styleUrl: './finished-task-detail.component.scss'
})
export class FinishedTaskDetailComponent implements OnInit {

  taskData: any = null;
  isLoading:boolean=false

  standardFieldKeys: string[] = [];
  uploadFieldKeys: string[] = [];
  hostname = environment.urlHostName
    
  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const taskId = params.get('id');
          if (taskId) {
            return this.dataService.getSelectedFinishedTask().pipe(
              switchMap((task) => {
                if (task?.id === taskId) {
                  return [task]; // Task is already selected
                } else {
                  return [this.dataService.getFinishedTaskById(taskId)];
                }
              }),
              catchError((error) => {
                console.error('Error fetching task:', error);
                this.notificationService.showError('Fehler: Daten konnten nicht geladen werden.');
                return of(null); // null in case of error
              })
            );
          }
          return of(null); // Handle if no taskId
        })
      )
      .subscribe((task) => {
        if (task) {
          this.populateFinishedTaskData(task);
        } else {
          //console.warn('No task found or error occurred');
          this.taskData = null;
        }
        this.isLoading = false;
      });
  }
  
  populateFinishedTaskData(task: any): void {
    this.taskData = task;

    //console.log('Finished Task Data:', this.taskData);

    const payload = task.payload || {};
    this.standardFieldKeys = Object.keys(payload).filter(
      (key) =>
        typeof payload[key] !== 'object' || 
        (!payload[key]?.fileName && !payload[key]?.contentType)
    );
    this.uploadFieldKeys = Object.keys(payload).filter(
      (key) => payload[key]?.fileName || payload[key]?.contentType
    );
  }

  getDownloadLink(key: any): string {
    return `https://${this.hostname}/v1/pass/${this.taskData.tridyId}/wt-${this.taskData.id}/WT-${key}.pdf`;
  }

}
