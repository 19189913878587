import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../material/material.module';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  userDisplayName: string = '';

  constructor(
    public authService: AuthService) {

      this.authService.user$.subscribe(user => {
        if (user) {
          this.userDisplayName = user.displayName || '';
        } else {
          this.userDisplayName = '';
        }
      });
  }

onInit(): void {
}

}
