import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ContextSelectionComponent } from '../context-selection/context-selection.component';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent implements OnInit {
  value:string = 'Clear me';
  @Output() toggleSidebar = new EventEmitter<void>();

  userDisplayName: string = '';
  contextIds: string[] = [];
  contextId: any = null;
  contextDisplayName: any = '';
  contextData: any = null;
  disableContextSwitch: boolean = false;

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private dataService: DataService // Inject DataService for fetching context IDs
  ) {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.userDisplayName = user.displayName || '';
        this.dataService.getDataUsersFirestore().subscribe(users => {
          const matchedUser = users.find((u: any) => u.id === user.uid);
          if (matchedUser?.contextIds?.length > 0) {
            this.contextIds = matchedUser.contextIds;
          }
        });
      } else {
        this.userDisplayName = '';
      }
    });

  }
  
  ngOnInit(): void {
    this.contextId = this.dataService.getLocalContextId();
  
    if (this.contextId) {
      this.fetchContextData();
    } else {
      //console.warn('No context ID found');
    }
  
    // Subscribe to context ID changes
    this.dataService.contextIdChange$.subscribe((newContextId) => {
      if (newContextId) {
        //console.log('Context ID changed:', newContextId);
        this.contextId = newContextId; // Update the current context ID
        this.fetchContextData(); // Fetch the new context data
      }
    });
  }

  fetchContextData(): void {
    this.dataService.getContextData(this.contextId).subscribe(
      (data) => {
        //console.log('Fetched Context Data:', data);
        this.contextData = data;
      },
      (error) => {
        console.error('Error fetching context data:', error);
      }
    );
  }

  openContextDialog(): void {
    if (this.contextIds.length > 1) {
      const dialogRef = this.dialog.open(ContextSelectionComponent, {
        width: '400px',
        data: this.contextIds
      });
  
      dialogRef.afterClosed().subscribe((selectedContextId) => {
        if (selectedContextId) {
          //console.log('Selected Context ID:', selectedContextId);
  
          // Save the selected contextId locally
          this.dataService.saveContextId(selectedContextId);
          this.dataService.contextIdChange$.next(selectedContextId);
  
          const currentRoute = window.location.pathname;
          if (currentRoute !== '/dasbboard') {
            window.location.href = '/dashboard';
          }
        } else {
          console.log('No context selected');
        }
      });
    } else {
      this.disableContextSwitch = true;
      console.warn('No more contexts available for the user.');
    }
  }

  logout() {
    this.authService.signOut();
    this.dataService.clearContextId(); // Clear saved contextId, disable after context check implementation
  }
}
