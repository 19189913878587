import { Component, Input, OnInit } from '@angular/core';
import { MaterialModule } from '../../material/material.module';
import { Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { environment } from '../../../environments/environment';
import { build, version } from '../../../../src/buildVersion';

@Component({
  selector: 'app-side-navigation',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './side-navigation.component.html',
  styleUrl: './side-navigation.component.scss'
})

export class SideNavigationComponent {
  @Input() opened = false;
  events: string[] = [];
  isGlowing = false;
  environmentName=environment.firebase
  buildEnvironment?:string
  version=version
  buildNumber=build


  constructor(
    private router: Router, 
    private navigationService: NavigationService) {
    //this.router.events.subscribe((event) => console.log(event));
  }

  ngOnInit(): void {
    this.navigationService.glowTrigger$.subscribe(() => {
      //console.log('Glow trigger received in SideNavigationComponent');
      this.triggerGlow();
    });

    if(this.environmentName.projectId=="narravero-eu-dev"){
      this.buildEnvironment="Narravero DEV "
    }
    if(this.environmentName.projectId=="narravero-eu-prod"){
      this.buildEnvironment="Narravero "
    }
    if(this.environmentName.projectId=="narravero-eu-test"){
      this.buildEnvironment="Narravero TEST "
    }

  }

  navigateTo(path: string): void {
    this.router.navigate([path]);
  }

  isActive(paths: string[]): boolean {
    return paths.some(path => {
      if (path.includes(':id')) {
        // Handle paths with dynamic parameters
        const basePath = path.split('/:')[0];
        return this.router.url.startsWith(basePath);
      }
      return this.router.url === path;
    });
  }

  toggle() {
    this.opened = !this.opened;
  }
  triggerGlow(): void {
    this.isGlowing = true;
    setTimeout(() => {
      this.isGlowing = false;
    }, 700); // Duration matches the CSS animation
  }
}
