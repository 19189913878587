import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormSection } from '../../models/form-field.model';
import { build, version } from '../../../../src/buildVersion';
import { NavigationService } from '../../services/navigation.service';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TridySelectionComponent } from '../../components/tridy-selection/tridy-selection.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-work-task-form',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, MatDatepickerModule, MatSelectModule, MatStepperModule],
  templateUrl: './work-task-form.component.html',
  styleUrl: './work-task-form.component.scss'
})
export class WorkTaskFormComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;
  form: FormGroup;
  tridyFormGroup: FormGroup;
  taskData: any = null;
  formSections: FormSection[] = [];
  isLoading:boolean=false
  isSubmitting: boolean = false;
  isSubmitted: boolean = false; 
  isError: boolean = false; 
  uploadedFiles: { [key: string]: File | null } = {};
  userId:any
  version:string=version
  buildNumber:number=build
  environmentName=environment.firebase
  envId:string=this.environmentName.projectId
  userDisplayName: any = '';
  contextId: any = '';

  allTridys: any[] = [];
  filteredTridys: any[] = [];
  hasTridys: boolean = false;
  selectedTridy: any = null;

  showTridySelection: boolean = false;

  constructor(
    private navigationService: NavigationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {

    this.tridyFormGroup = this.fb.group({
      tridySelection: ['', Validators.required],
    });

    this.form = this.fb.group({
      sections: this.fb.array([]), // Dynamic sections
    });
  }

  ngOnInit(): void {
    
    this.authService.user$.subscribe((user) => {
      this.userId = user?.uid;
      this.userDisplayName = user?.displayName;
    });

    this.isLoading = true;
    this.route.paramMap
    .pipe(
      switchMap((params) => {
        const taskId = params.get('id');
        if (taskId) {
          return this.dataService.getSelectedTask().pipe(
            switchMap((task) => {
              if (task?.id === taskId) {
                return [task]; // Task is already selected
              } else {
                return [this.dataService.getTaskById(taskId)];

              }
            }),
            catchError((error) => {
              console.error('Error fetching task:', error);
              return of(null);
            })
          );
        }
        return of(null); // Handle if no taskId
      })
    )
    .subscribe((task) => {
      if (task) {
        this.showTridySelection = task.taskType === 'update';
        if (this.showTridySelection) {
          this.filteredTridys = [];
        }
        this.populateTaskData(task);
      } else {
        this.taskData = null;
        console.warn('No task found or error occurred');

      }
      this.isLoading = false;
    });

    this.contextId = this.dataService.getLocalContextId();
  }

  populateTaskData(task: any): void {
    this.resetStepper();
    this.selectedTridy = null;
    this.taskData = task;

    const sectionsArray = this.form.get('sections') as FormArray;
    sectionsArray.clear();
  
    task.formSections.forEach((section: any) => {
      const sectionGroup = this.fb.group({});
      section.fields.forEach((field: any) => {
        // Add validation dynamically based on `mandatory`
        const validators = field.mandatory ? [Validators.required] : [];
        
        if (field.type === 'dateTime') {
          // Add separate controls for date and time
          sectionGroup.addControl(field.key, this.fb.control(null, validators));
          sectionGroup.addControl(field.key + '_time', this.fb.control(null, validators));
        }

        if (field.type === 'document') {
          // Add a placeholder control for file uploads
          sectionGroup.addControl(field.key, this.fb.control(null, validators));
        } else {
          sectionGroup.addControl(
            field.key,
            this.fb.control({ value: '', disabled: field.readOnly }, validators)
          );
        }
      });
      sectionsArray.push(sectionGroup);
    });
  }

  populateExistingTridyValues(tridy: any): void {
    if (tridy?.payload) {
      //console.log('Populating existing Tridy values:', tridy.payload);
  
      Object.keys(tridy.payload).forEach((key) => {

        const value = tridy.payload[key];
        const sectionsArray = this.form.get('sections') as FormArray;
  
        let controlFound = false;
        sectionsArray.controls.forEach((sectionControl: AbstractControl, index: number) => {

          const sectionGroup = sectionControl as FormGroup;
  
          if (sectionGroup.contains(key)) {
            const field = this.taskData.formSections[index].fields.find(
              (field: any) => field.key === key
            );
            // Skip document fields
            if (field?.type !== 'document') {
              sectionGroup.get(key)?.patchValue(value);
            }
            controlFound = true;
          }
        });
  
        if (!controlFound) {
          console.warn(`Form control for ${key} not found in any section`);
        }
      });
    }
  }

  openTridySelectionDialog(): void {

    const contentTypeId = this.taskData.contentType;
    if (contentTypeId) {

      //console.log(`Loading Tridys for Content Type: ${contentTypeId}`);
      this.dataService.getTridys(this.contextId, contentTypeId).then((tridys) => {
        this.filteredTridys = tridys;
        //console.log(this.filteredTridys);
        
        const dialogRef = this.dialog.open(TridySelectionComponent, {
          width: '420px',
          height: '660px',
          data: this.filteredTridys
        });

        dialogRef.afterClosed().subscribe((selectedTridyId) => {
          if (selectedTridyId) {

            // Find the selected Tridy details from the allTridys array
            this.selectedTridy = this.filteredTridys.find(tridy => tridy.id === selectedTridyId);
            // Update the form control
            if (this.selectedTridy) {
              this.tridyFormGroup.get('tridySelection')?.setValue(this.selectedTridy);
              this.tridyFormGroup.get('tridySelection')?.markAsTouched();
            }
            console.log(this.selectedTridy)
            // Populate form fields based on selected Tridy
            this.populateExistingTridyValues(this.selectedTridy);
          }
        });

      });

    } else {
      this.notificationService.showError('Fehler: Inhaltstyp dieser Aufgabe enthält noch keine Tridys.');
      console.error('This ContentType has no Tridys');
    }
  }
 
  resetStepper(): void {
    if (this.stepper) {
      this.stepper.reset();
    }
  }

  get sections(): FormArray {
    return this.form.get('sections') as FormArray;
  }

  getSectionGroup(index: number): FormGroup {
    return this.sections.at(index) as FormGroup;
  }

  private formatDateGerman(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    };
    return new Intl.DateTimeFormat('de-DE', options).format(date);
  }

  onFileSelected(event: Event, fieldKey: string): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      const file = input.files[0];
      const validTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];

      if (!validTypes.includes(file.type)) {
        console.error('Unsupported file type:', file.type);
        this.notificationService.showError('Fehler: Nur Bilder und PDF-Dateien sind erlaubt.', 4000);
        return;
      }
  
      this.uploadedFiles[fieldKey] = file;
      //console.log(`File selected for ${fieldKey}:`, file);

      const control = this.form.get(`sections.${fieldKey}`);
      if (control) {
        control.setValue(file.name);
      }
    }
  }
  
  clearFile(fieldKey: string): void {
    this.uploadedFiles[fieldKey] = null;
    //console.log(`File cleared for ${fieldKey}`);
  
    const control = this.form.get(`sections.${fieldKey}`);
    if (control) {
      control.reset();
    }
  }

  private extractPayload(formData: any): any {
    const payload: any = {};
    formData.sections.forEach((section: any, sectionIndex: number) => {
      Object.keys(section).forEach((key: string) => {
        if (key.endsWith('_time')) return; // Skip the time-only fields

        if (this.isDateTimeField(key, section)) {
          const date = section[key];
          const time = section[key + '_time'];
  
          if (date && time) {
            const [hours, minutes] = time.split(':');
            const combinedDateTime = new Date(date);
            combinedDateTime.setHours(+hours, +minutes);
            payload[key] = this.formatDateGerman(combinedDateTime);
          }
        } else if (this.uploadedFiles[key]) {

          const file = this.uploadedFiles[key];

          if (file) {
            const mimeType = file.type || 'unknown';
            const visibility = this.getVisibilityFromTemplate(key);
          
            let contentType = 'unknown';
            let fileName = null;
            if (mimeType.startsWith('image/')) {
              contentType = 'public.image';
            } else if (mimeType === 'application/pdf') {
              contentType = 'com.adobe.pdf';
              fileName = file.name || 'unknown'; // fileName only for PDFs
            }

            const fieldId = this.getFieldIdFromTemplate(sectionIndex, key);
  
            payload[key] = {
              contentType,
              id: fieldId,
              ...(fileName ? { fileName } : {}), // Add fileName only if it's not null
              payload: {},
              visibility,
            };
          }          
        } 
        else if (section[key] !== '') {
          payload[key] = section[key];
        }
      });
    });
    return payload;
  }

  // Get field ID from the template
  private getFieldIdFromTemplate(sectionIndex: number, key: string): string | null {
    const section = this.taskData.formSections[sectionIndex];
    if (!section) return null;

    const field = section.fields.find((field: any) => field.key === key);
    return field ? field.id : null;
  }

  // THIS IS BASED ON META DATA FROM THE TEMPLATE FIELD SETTINGS, SETTING NOT AVAILABLE IN THE WEB PUBLISHER
  private getVisibilityFromTemplate(fieldKey: string): string {
    const matchingField = this.taskData?.formSections
      ?.flatMap((section: any) => section.fields)
      ?.find((field: any) => field.key === fieldKey);
    return matchingField?.visibility || 'visible';
  }
      
  private isDateTimeField(key: string, section: any): boolean {
    return section[key + '_time'] !== undefined;
  }

  onSubmit(): void {

    if (this.form.valid) {
      const contextId = this.dataService.getLocalContextId();
      if (!contextId) {
        this.notificationService.showError('Fehler: Kein Konto verfügbar.', 4000);
        console.error('No context ID available.');
        return;
      }
      
      let existingTridy: string | undefined;
      if (this.taskData.taskType === 'update') {
        existingTridy = this.selectedTridy.id;
        //console.log('Existing Tridy will be updated:', existingTridy);
      }

      this.isSubmitting = true;
      this.isSubmitted = false;

      const formData = this.form.value;

      // Keys to preserve - remove null values
      const documentKeys = Object.keys(this.uploadedFiles || {});
      formData.sections = formData.sections.map((section: any) =>
        this.cleanFormData(section, documentKeys)
      );
    
      const payload = this.extractPayload(formData);
      //console.log('Cleaned payload:', payload);
  
      const workTaskData = {
        appBuildNo: this.buildNumber || '',
        appName: document.title || undefined,
        appPlatform: (navigator as any).userAgentData?.platform || '',
        appVersion: this.version || undefined,
        creationDate: new Date(),
        deviceName: navigator.userAgent,
        incomingTridys: this.taskData.incomingTridys || [],
        outgoingTridys: this.taskData.outgoingTridys || [],
        payload: payload,
        template: this.taskData,
        templateId: this.taskData.id,
        timeStamp: new Date(),
        title: this.taskData.title || '',
        tridyId: null, // updated after creating the Tridy
        userId: this.userId || '',
        userDisplayName: this.userDisplayName || ''
      };

      //console.log('Submitting WorkTask:', workTaskData);

      // Save Task and Upload Files, optionally update existing Tridy if selected
      this.saveTask(contextId, workTaskData, existingTridy)
      .then(() => {
        //console.log('Task and files uploaded successfully.');
        this.isSubmitting = false;
        this.isSubmitted = true;
        this.selectedTridy = false
        this.tridyFormGroup.reset();
        
        // Trigger glow effect on navigation
        this.navigationService.triggerGlow();

        // Reset after 3 seconds
        setTimeout(() => {
          this.isSubmitted = false;
          this.form.reset();
        }, 3000);
      })
      .catch((error) => {
        console.error('Error uploading form and task data:', error);
        this.notificationService.showError('Fehler: Die Daten konnten nicht gesendet werden.');
        this.isError = true;
        // Reset after 5 seconds
        setTimeout(() => {
          this.isSubmitted = false;
          this.isError = false;
          this.form.reset();
        }, 5000);
        
      });
      } else {
        console.error('Form is invalid. Please fill in all required fields.');
        this.notificationService.showError('Fehler: Formular ist ungültig. Bitte füllen Sie alle erforderlichen Felder aus!');
        this.form.markAllAsTouched();
      }
  }

  private cleanFormData(data: any, preserveKeys: string[] = []): any {
    if (Array.isArray(data)) {
      return data
        .map((item) => this.cleanFormData(item, preserveKeys))
        .filter((item) => item !== null && item !== undefined);
    } else if (typeof data === 'object' && data !== null) {
      const cleanedObject: any = {};
      Object.keys(data).forEach((key) => {
        const value = data[key];

        if (value === null && preserveKeys.includes(key)) {
          cleanedObject[key] = null;
        } else if (value !== null && value !== undefined) {
          cleanedObject[key] = this.cleanFormData(value, preserveKeys); // Recursively clean
        }
      });
      return cleanedObject;
    }
    return data; // Return primitive values as-is
  }
  
  private async saveTask(contextId: string, workTaskData: any, tridyId?: string): Promise<void> {

    // Update Tridy if tridyId is provided
    if (tridyId) {
      console.log('Updating existing Tridy with ID:', tridyId);

      try {

        ///////////////////////////
        /* Update Existing Tridy */
        ///////////////////////////

        // REFACTOR THIS ONE!
        await this.dataService.updateExistingTridy(tridyId, workTaskData);

        //console.log(`Resolved Tridy ID: ${tridyId}`);
  
        // Save the work task first to generate the finished task ID
        const finishedTaskId = await this.dataService.saveWorkTask(contextId, { ...workTaskData, tridyId });
        //console.log(`Resolved Finished Task ID: ${finishedTaskId}`);
  
        const uploadedFilesUrls: {
          [key: string]: { primaryUrl: string; secondaryUrl: string; mimeType?: string; fileName?: string };
        } = {};
  
        // Upload to Firebase Storage (if any)
        for (const [fieldKey, file] of Object.entries(this.uploadedFiles)) {
          if (file) {
            const { primaryUrl, secondaryUrl } = await this.dataService.uploadFile(
              tridyId,
              finishedTaskId,
              fieldKey,
              file
            );
  
            // Extract MIME and file name
            const mimeType = file.type || undefined;
            const fileName = file.name || 'unknown.file';
  
            // Save URLs and metadata for the field
            uploadedFilesUrls[fieldKey] = { primaryUrl, secondaryUrl, mimeType, fileName };
          }
        }
        // Define the type
        type FileMeta = {
          contentType?: string;
          id?: string;
          visibility?: string;
        };
  
        // Update the Tridy document with the finished task ID and cleaned payload
        await this.dataService.updateExistingTridyWithWorkTask(tridyId, finishedTaskId, workTaskData);
  
        console.log('Files uploaded and task updated successfully.');
        this.notificationService.showSuccess('Aufgabe erfolgreich abgeschlossen.');
      } catch (error) {
        this.isError = true;
        this.isSubmitting = false;
        // Reset after 5 seconds
        setTimeout(() => {
          this.isSubmitted = false;
          this.isError = false;
          this.form.reset();
        }, 5000);
        console.error('Error uploading files or saving task data:', error);
        this.notificationService.showSuccess('Aufgabe konnte nicht abgeschlossen werden.');
        throw error;
      }

    }

    ///////////////////////////
    /* Create New Tridy */
    ///////////////////////////

    else{
      console.log('Creating new Tridy');
      try {
        // Ensure Tridy ID is created before uploading files
        const tridyId = workTaskData.tridyId || (await this.dataService.createTridy(contextId, workTaskData, this.envId));
        //(`Resolved Tridy ID: ${tridyId}`);
  
        // Save the work task first to generate the finished task ID
        const finishedTaskId = await this.dataService.saveWorkTask(contextId, { ...workTaskData, tridyId });
        //console.log(`Resolved Finished Task ID: ${finishedTaskId}`);
  
        const uploadedFilesUrls: {
          [key: string]: { primaryUrl: string; secondaryUrl: string; mimeType?: string; fileName?: string };
        } = {};
  
        // Upload to Firebase Storage (if any)
        for (const [fieldKey, file] of Object.entries(this.uploadedFiles)) {
          if (file) {
            const { primaryUrl, secondaryUrl } = await this.dataService.uploadFile(
              tridyId,
              finishedTaskId,
              fieldKey,
              file
            );
  
            // Extract MIME and file name
            const mimeType = file.type || undefined;
            const fileName = file.name || 'unknown.file';
  
            // Save URLs and metadata for the field
            uploadedFilesUrls[fieldKey] = { primaryUrl, secondaryUrl, mimeType, fileName };
          }
        }
        // Define the type
        type FileMeta = {
          contentType?: string;
          id?: string;
          visibility?: string;
        };
  
        // Create a cleaned payload for files and other fields
        const cleanedPayload = Object.fromEntries(
          Object.entries(workTaskData.payload).map(([key, value]) => {
            if (uploadedFilesUrls[key]) {
              const fileMeta = (value || {}) as FileMeta;
              const { primaryUrl, secondaryUrl, mimeType, fileName } = uploadedFilesUrls[key];
  
              return [
                key,
                {
                  ...fileMeta,
                  primaryUrl,
                  secondaryUrl,
                  contentType: fileMeta.contentType || mimeType,
                  id: fileMeta.id,
                  ...(fileMeta.visibility ? { visibility: fileMeta.visibility } : {}),
                  ...(mimeType?.startsWith('image/') ? { mimeType } : {}), // Include mimeType only for images
                  fileName,
                },
              ];
            }
            return [key, value];
          })
        );
  
        // Update the Tridy document with the finished task ID and cleaned payload
        await this.dataService.updateNewTridyWithWorkTask(tridyId, finishedTaskId, workTaskData, cleanedPayload);
  
        console.log('Files uploaded and task saved successfully.');
        this.notificationService.showSuccess('Aufgabe erfolgreich abgeschlossen.');
      } catch (error) {
        this.isError = true;
        this.isSubmitting = false;
        // Reset after 5 seconds
        setTimeout(() => {
          this.isSubmitted = false;
          this.isError = false;
          this.form.reset();
        }, 5000);
        console.error('Error uploading files or saving task data:', error);
        this.notificationService.showSuccess('Aufgabe konnte nicht abgeschlossen werden.');
        throw error;
      }
    }
  }

  private cleanFieldData(value: any): any {
    if (value === null || value === undefined) {
      return undefined; // Replace null/undefined with undefined to omit the field
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      const cleanedObject: any = {};
      Object.keys(value).forEach((key) => {
        if (value[key] !== null && value[key] !== undefined) {
          cleanedObject[key] = this.cleanFieldData(value[key]); // Recursively clean nested objects
        }
      });
      return cleanedObject;
    }
    return value; // Return primitive values as-is
  }

}
