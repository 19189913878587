import { Routes } from '@angular/router';
import { LogInComponent } from './auth/log-in/log-in.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { WorkTaskListComponent } from './main/work-task-list/work-task-list.component';
import { FinishedTaskListComponent } from './main/finished-task-list/finished-task-list.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LogInComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'tasks', component: WorkTaskListComponent },
      { path: 'tasks/:id', component: WorkTaskListComponent },
      { path: 'finished-tasks', component: FinishedTaskListComponent },
      { path: 'finished-tasks/:id', component: FinishedTaskListComponent },
    ],
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];
