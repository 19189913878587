<div class="nav-wrapper">
    <div class="nav-group">
      
        <mat-nav-list>
          <mat-list-item [class.active]="isActive(['/dashboard'])" (click)="navigateTo('/dashboard')">
              <span class="item-label"><mat-icon class="icon-margin">dashboard</mat-icon> Übersicht</span>
          </mat-list-item>
          <mat-list-item  [class.active]="isActive(['/tasks', '/tasks/:id'])" (click)="navigateTo('/tasks')">
              <span class="item-label"><mat-icon class="icon-margin">fact_check</mat-icon> Aufgaben</span>
          </mat-list-item>
<!--           <mat-list-item disabled [class.active]="isActive(['/task-groups'])" (click)="navigateTo('/task-groups')">
            <span class="item-label"><mat-icon class="icon-margin">group_work</mat-icon> Gruppen</span>
          </mat-list-item> -->
          <mat-list-item [class.glow]="isGlowing" [class.active]="isActive(['/finished-tasks', '/finished-tasks/:id'])" (click)="navigateTo('/finished-tasks')">
            <span class="item-label"><mat-icon class="icon-margin">task</mat-icon> Erledigte Aufgaben</span>
          </mat-list-item>
        </mat-nav-list>

        <!-- <mat-list> 
            <mat-list-item>
            <h3>Aufgaben:</h3>
          </mat-list-item> 
            <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>example</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list dense>
                <mat-list-item>example item</mat-list-item>
                <mat-list-item>example item</mat-list-item>
                <mat-list-item>example item</mat-list-item>
                <mat-list-item>example item</mat-list-item>
                <mat-list-item>example item</mat-list-item>
                
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion> -->

    </div>

    <div class="text-center p-2">
      <small class="dev-note">{{buildEnvironment}} - {{version}} ( {{buildNumber}} )</small>
    </div>

      
<!--     <div class="bottom-icons">

        <mat-nav-list>
            <mat-list-item disabled>
                <span disabled class="item-label"><mat-icon class="icon-margin">check_circle</mat-icon> Erledigte Aufgaben</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item disabled>
                <span  class="item-label"><mat-icon class="icon-margin">help</mat-icon> Hilfe</span>
            </mat-list-item>
        </mat-nav-list>

        <button mat-button>
          <mat-icon>check_circle</mat-icon>
          Erledigte Aufgaben
        </button>
        <button mat-button>
          <mat-icon>help</mat-icon>
          Hilfe
        </button>
    </div> -->
</div>
