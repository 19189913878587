import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private glowTrigger = new Subject<void>();
  glowTrigger$ = this.glowTrigger.asObservable();

  triggerGlow(): void {
    this.glowTrigger.next();
  }

  // TODO: 
  // Support route change after form submission (see newest finished task)
  // Support Breadcrumbs
  // Preload Data
  // Detect Back and Forward navigation
  // Check activated routes
  // Navigation Logging
}
