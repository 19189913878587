import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormSection } from '../../models/form-field.model';
import { build, version } from '../../../../src/buildVersion';
import { NavigationService } from '../../services/navigation.service';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TridySelectionComponent } from '../../components/tridy-selection/tridy-selection.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-work-task-form',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, MatDatepickerModule, MatSelectModule, MatStepperModule],
  templateUrl: './work-task-form.component.html',
  styleUrl: './work-task-form.component.scss'
})
export class WorkTaskFormComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;
  form: FormGroup;
  tridyFormGroup: FormGroup;
  taskData: any = null;
  formSections: FormSection[] = [];
  isLoading:boolean=false
  isSubmitting: boolean = false;
  isSubmitted: boolean = false; 
  isError: boolean = false; 
  uploadedFiles: { [key: string]: File | null } = {};
  userId:any
  version:string=version
  buildNumber:number=build
  environmentName=environment.firebase
  envId:string=this.environmentName.projectId
  userDisplayName: any = '';
  contextId: any = '';
  printActive: boolean = false;
  importedMedia: any = null;
  workflowActive: boolean = false;
  workflowParameters: any = null;
  titleKey: string | null = null;
  subtitleKeys: string[] = [];
  allTridys: any[] = [];
  hasTridys: boolean = false;
  selectedTridy: any = null;
  existingIdentifiers: any
  duplicateIdentifiers: boolean = false;

  showTridySelection: boolean = false;

  constructor(
    private navigationService: NavigationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private helperService: HelperService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {

    this.tridyFormGroup = this.fb.group({
      tridySelection: ['', Validators.required],
    });

    this.form = this.fb.group({
      sections: this.fb.array([]), // Dynamic sections
    });
  }

  ngOnInit(): void {
    
    this.authService.user$.subscribe((user) => {
      this.userId = user?.uid;
      this.userDisplayName = user?.displayName;
    });

    this.isLoading = true;
    this.route.paramMap
    .pipe(
      switchMap((params) => {
        const taskId = params.get('id');
        if (taskId) {
          return this.dataService.getSelectedTask().pipe(
            switchMap((task) => {
              if (task?.id === taskId) {
                return [task]; // Task is already selected
              } else {
                return [this.dataService.getTaskById(taskId)];

              }
            }),
            catchError((error) => {
              console.error('Error fetching task:', error);
              return of(null);
            })
          );
        }
        return of(null); // Handle if no taskId
      })
    )
    .subscribe((task) => {
      if (task) {
        this.showTridySelection = task.taskType === 'update';
        this.populateTaskData(task);
      } else {
        this.taskData = null;
        //console.warn('No task found or error occurred');
      }
      this.isLoading = false;
    });

    this.contextId = this.dataService.getLocalContextId();
  }

  populateTaskData(task: any): void {
    this.resetStepper();
    this.selectedTridy = null;
    this.taskData = task;

    // DDL Case, check if Print action is available
    const printAction = this.taskData.actions.find((action:any) => action.action === 'print');
    this.printActive = !!printAction;

    // DDL Case, check if Workflow action is available
    const workflowAction = this.taskData.actions.find((action:any) => action.action === 'startWorkflow');
    this.workflowActive = !!workflowAction;
    if (workflowAction) {
      this.workflowParameters = workflowAction.parameters;
    }
    
    const sectionsArray = this.form.get('sections') as FormArray;
    sectionsArray.clear();
  
    task.formSections.forEach((section: any) => {
      const sectionGroup = this.fb.group({});
      section.fields.forEach((field: any) => {
        // Add validation dynamically based on `mandatory`
        const validators = field.mandatory ? [Validators.required] : [];
        
        if (field.type === 'dateTime') {
          // Add separate controls for date and time
          sectionGroup.addControl(field.key, this.fb.control(null, validators));
          sectionGroup.addControl(field.key + '_time', this.fb.control(null, validators));
        }

        if (field.type === 'document') {
          // Add a placeholder control for file uploads
          sectionGroup.addControl(field.key, this.fb.control(null, validators));
        } else {
          sectionGroup.addControl(
            field.key,
            //this.fb.control({ value: '', disabled: field.readOnly }, validators)
            this.fb.control( null, validators)
          );
        }
      });
      sectionsArray.push(sectionGroup);
    });
  }

  populateExistingTridyValues(tridy: any): void {
    if (tridy?.payload) {
      //console.log('Populating existing Tridy values:', tridy.payload);
  
      Object.keys(tridy.payload).forEach((key) => {

        const value = tridy.payload[key];
        const sectionsArray = this.form.get('sections') as FormArray;
  
        let controlFound = false;
        sectionsArray.controls.forEach((sectionControl: AbstractControl, index: number) => {

          const sectionGroup = sectionControl as FormGroup;
          if (sectionGroup.contains(key)) {
            const field = this.taskData.formSections[index].fields.find(
              (field: any) => field.key === key
            );
            // Skip document fields
            if (field?.type !== 'document') {
              sectionGroup.get(key)?.patchValue(value);
            }
            controlFound = true;
          }
        });
  
        if (!controlFound) {
          console.warn(`Form control for ${key} not found in any section`);
        }
      });
    }
  }

  populateExistingTridyFieldValues(tridy: any, importedFields: any[]): void {
    if (tridy?.payload && importedFields?.length > 0) {
      // Loop through the imported fields
      importedFields.forEach((importedField) => {
        const { key, tridyPayloadKey } = importedField;
  
        // Check if the tridy payload contains the tridyPayloadKey
        const value = tridy.payload[tridyPayloadKey];
        if (value !== undefined) {
          const sectionsArray = this.form.get('sections') as FormArray;
          let controlFound = false;
  
          // Iterate through each section
          sectionsArray.controls.forEach((sectionControl: AbstractControl, index: number) => {
            const sectionGroup = sectionControl as FormGroup;
            const control = sectionGroup.get(key);
            if (control) {
              control.setValue(value);
              const field = this.taskData.formSections[index].fields.find(
                (field:any) => field.key === key
              );
              if (field?.type !== 'document') {
                control.patchValue(value);
              }
              controlFound = true;
            }
          });
  
          if (!controlFound) {
            console.warn(`Form control for key "${key}" not found in any section.`);
          }
        } else {
          //console.warn(`No value found in tridy payload for tridyPayloadKey "${tridyPayloadKey}".`);
        }
      });
    }
    else {
      console.warn('No defined fields to import or no tridy payload found.');
    }
  }

  handleMediaImports(tridy: any, importMedia: any) {
    // Search for media in selected tridy and replace the key, based on source and target key, defined in importMedia
    if (tridy?.media && importMedia?.length > 0) {
      const newMedia = tridy.media.map((mediaObject:any) => {
        const importedMediaObj = importMedia.find((importedMediaObj:any) => importedMediaObj.sourceKey === mediaObject.key);
        if (importedMediaObj) {
          return {...mediaObject, key: importedMediaObj.targetKey};
        }
        else {
          return mediaObject;
        }
      });
      //console.log(newMedia)
      return newMedia;
    }
    else {
      console.warn('No media found in selected tridy or no import media defined.');
    }
  }

  openTridySelectionDialog(): void {

    const contentTypeId = this.taskData.contentType;
    if (contentTypeId) {

      this.dataService.getContentTypeTitleKey(this.contextId, contentTypeId)
      .then((titleKey) => {
        this.titleKey = titleKey;
        //console.log('Title Key:', titleKey);
      })
      .catch((error) => {
        console.error('Error fetching titleKey:', error);
      });

      this.dataService.getContentTypeSubtitleKeys(this.contextId, contentTypeId)
      .then((subtitleKeys) => {
        this.subtitleKeys = subtitleKeys;
        //console.log('Subtitle Keys:', subtitleKeys);
      })
      .catch((error) => {
        console.error('Error fetching Subtitle Keys:', error);
      });

      this.dataService.getTridys(this.contextId, contentTypeId).then((tridys) => {   
        
        const dialogData: any = {
          tridys: tridys
        };
        
        if (this.titleKey && this.titleKey.length > 0) {
          dialogData.titleKey = this.titleKey;
        }

        if (this.subtitleKeys && this.subtitleKeys.length > 0) {
          dialogData.subtitleKeys = this.subtitleKeys;
        }

        const dialogRef = this.dialog.open(TridySelectionComponent, {
          width: '420px',
          height: '660px',
          data: dialogData
        });

        dialogRef.afterClosed().subscribe((selectedTridyId) => {
          if (selectedTridyId) {
            // Find the selected Tridy details
            this.selectedTridy = tridys.find(tridy => tridy.id === selectedTridyId);
            // Update the form control
            if (this.selectedTridy) {
              this.tridyFormGroup.get('tridySelection')?.setValue(this.selectedTridy);
              this.tridyFormGroup.get('tridySelection')?.markAsTouched();
            }
            //console.log(this.selectedTridy)
            this.populateExistingTridyValues(this.selectedTridy);
          }
        });

      });

    } else {
      this.notificationService.showError('Fehler: Inhaltstyp dieser Aufgabe enthält noch keine Tridys.');
      console.error('This ContentType has no Tridys');
    }
  }

  openTridySelectionFieldDialog(contentTypeId:string, importedFields?:any, importedMedia?:any, key?:string): void {

    if (contentTypeId) {

      this.dataService.getContentTypeTitleKey(this.contextId, contentTypeId)
      .then((titleKey) => {
        this.titleKey = titleKey;
       // console.log('Title Key:', titleKey);
      })
      .catch((error) => {
        console.error('Error fetching titleKey:', error);
      });

      this.dataService.getContentTypeSubtitleKeys(this.contextId, contentTypeId)
      .then((subtitleKeys) => {
        this.subtitleKeys = subtitleKeys;
        //console.log('Subtitle Keys:', subtitleKeys);
      })
      .catch((error) => {
        console.error('Error fetching Subtitle Keys:', error);
      });

    
      //console.log(`Loading Tridys for Content Type: ${contentTypeId}`);
      this.dataService.getTridys(this.contextId, contentTypeId).then((tridys) => {

        const dialogData: any = {
          tridys: tridys
        };
        
        if (this.titleKey && this.titleKey.length > 0) {
          dialogData.titleKey = this.titleKey;
        }

        if (this.subtitleKeys && this.subtitleKeys.length > 0) {
          dialogData.subtitleKeys = this.subtitleKeys;
        }

        const dialogRef = this.dialog.open(TridySelectionComponent, {
          width: '420px',
          height: '660px',
          data: dialogData
        });

        dialogRef.afterClosed().subscribe((selectedTridyId) => {
          if (selectedTridyId) {

            // Find the selected Tridy details
            this.selectedTridy = tridys.find(tridy => tridy.id === selectedTridyId);
            this.populateExistingTridyFieldValues(this.selectedTridy, importedFields);

            // If selected Tridy has media then import
            if (importedMedia) {
              this.importedMedia = this.handleMediaImports(this.selectedTridy, importedMedia);
              if (this.importedMedia) {
                this.importedMedia.forEach((mediaObject: any) => {
                  if (mediaObject.files) {
                    mediaObject.files.forEach((fileObject: any) => {
                      if (fileObject.url) {
                        const newUrl = fileObject.url.replace('tridy://', `https://${environment.urlHostName}/v1/pass/${this.selectedTridy?.id}/`);
                        fileObject.url = newUrl;
                      }
                      else {
                        console.warn('No URL found in imported media object.');
                      }
                    });
                  }
                  else {
                    console.warn('No files found in imported media object.');
                  }
                });
              }
            }

            if (key) {
              const control = Object.keys((this.form.controls['sections'] as FormGroup).controls).find((sectionKey: string) => {
                const sectionGroup = (this.form.controls['sections'] as FormGroup).controls[sectionKey] as FormGroup;
                return sectionGroup && sectionGroup.controls[key];
              });
              if (control) {
                const controlGroup = (this.form.controls['sections'] as FormGroup).controls[control] as FormGroup;
                controlGroup.controls[key].setValue(selectedTridyId);
              } else {
                console.warn(`Form control for key '${key}' not found in any section.`);
              }
            } 
          }
        });
      });

    } else {
      this.notificationService.showError('Fehler: Inhaltstyp dieser Aufgabe enthält noch keine Tridys oder ist nicht vorhanden.');
      console.error('This ContentType has no Tridys or is not available.');
    }
  }
 
  resetStepper(): void {
    if (this.stepper) {
      this.stepper.reset();
    }
  }

  get sections(): FormArray {
    return this.form.get('sections') as FormArray;
  }

  getSectionGroup(index: number): FormGroup {
    return this.sections.at(index) as FormGroup;
  }

  onFileSelected(event: Event, fieldKey: string): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      const file = input.files[0];
      const validTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];

      if (!validTypes.includes(file.type)) {
        console.error('Unsupported file type:', file.type);
        this.notificationService.showError('Fehler: Nur Bilder und PDF-Dateien sind erlaubt.', 4000);
        return;
      }
  
      this.uploadedFiles[fieldKey] = file;
      //console.log(`File selected for ${fieldKey}:`, file);

      const control = this.form.get(`sections.${fieldKey}`);
      if (control) {
        control.setValue(file.name);
      }
    }
  }
  
  clearFile(fieldKey: string): void {
    this.uploadedFiles[fieldKey] = null;
    const control = this.form.get(`sections.${fieldKey}`);
    if (control) {
      control.reset();
    }
  }

  private extractPayload(formData: any): any {
    const payload: any = {};
    formData.sections.forEach((section: any, sectionIndex: number) => {
      Object.keys(section).forEach((key: string) => {
        if (key.endsWith('_time')) return; // Skip the time-only fields

        if (this.helperService.isDateTimeField(key, section)) {
          const date = section[key];
          const time = section[key + '_time'];
  
          if (date && time) {
            const [hours, minutes] = time.split(':');
            const combinedDateTime = new Date(date);
            combinedDateTime.setHours(+hours, +minutes);
            payload[key] = this.helperService.formatDateGerman(combinedDateTime);
          }
        } else if (this.uploadedFiles[key]) {

          const file = this.uploadedFiles[key];

          if (file) {
            const mimeType = file.type || 'unknown';
            const visibility = this.getVisibilityFromTemplate(key);
          
            let contentType = 'unknown';
            let fileName = null;
            if (mimeType.startsWith('image/')) {
              contentType = 'public.image';
            } else if (mimeType === 'application/pdf') {
              contentType = 'com.adobe.pdf';
              fileName = file.name || 'unknown'; // fileName only for PDFs
            }

            const fieldId = this.helperService.getFieldIdFromTemplate(this.taskData, sectionIndex, key);
  
            payload[key] = {
              contentType,
              id: fieldId,
              ...(fileName ? { fileName } : {}), // Add fileName only if it's not null
              payload: {},
              visibility,
            };
          }          
        } 
        else if (section[key] !== '') {
          payload[key] = section[key];
        }
      });
    });
    return payload;
  }

  // THIS IS BASED ON META DATA FROM THE TEMPLATE FIELD SETTINGS, SETTING NOT AVAILABLE IN THE WEB PUBLISHER
  private getVisibilityFromTemplate(fieldKey: string): string {
    const matchingField = this.taskData?.formSections
      ?.flatMap((section: any) => section.fields)
      ?.find((field: any) => field.key === fieldKey);
    return matchingField?.visibility || 'visible';
  }

  onSubmit(print: boolean, workflow: boolean): void {

    if (this.form.valid) {
      const contextId = this.dataService.getLocalContextId();
      if (!contextId) {
        this.notificationService.showError('Fehler: Kein Konto verfügbar.', 4000);
        console.error('No context ID available.');
        return;
      }
      
      let existingTridy: string | undefined;
      if (this.taskData.taskType === 'update') {
        existingTridy = this.selectedTridy.id;
        //console.log('Existing Tridy will be updated:', existingTridy);
      }

      this.isSubmitting = true;
      this.isSubmitted = false;

      const formData = this.form.value;
      // Keys to preserve - remove null values
      const documentKeys = Object.keys(this.uploadedFiles || {});
      formData.sections = formData.sections.map((section: any) =>
        this.helperService.cleanFormData(section, documentKeys)
      );
      const payload = this.extractPayload(formData);
      const workTaskData = {
        appBuildNo: `${this.buildNumber}` || '',
        appName: document.title || undefined,
        appPlatform: (navigator as any).userAgentData?.platform || '',
        appVersion: this.version || undefined,
        creationDate: new Date(),
        deviceName: navigator.userAgent,
        incomingTridys: this.taskData.incomingTridys || [],
        outgoingTridys: this.taskData.outgoingTridys || [],
        payload: payload,
        template: this.taskData,
        templateId: this.taskData.id,
        timeStamp: new Date(),
        title: this.taskData.title || '',
        tridyId: null, // updated after creating the Tridy
        userId: this.userId || '',
        userDisplayName: this.userDisplayName || ''
      };

      // Check for Identifier fields and abort if any are existing

      const filteredPayload = Object.entries(workTaskData.payload || {}).reduce(
        (result, [key, value]) => {
          const fieldInTemplate = workTaskData.template?.formSections
            ?.flatMap((section: any) => section.fields)
            ?.find((field: any) => field.payloadKey === key);
          if (fieldInTemplate) {
            result[key] = value;
          }
          return result;
        },
        {} as Record<string, any>
      );  
      const identifierFields = workTaskData.template?.formSections
      ?.flatMap((section: any) => section.fields)
      ?.filter((field: any) => field.identifierKey && filteredPayload[field.payloadKey]);
  
      if (identifierFields) {
        // Check for duplicate identifiers
        this.dataService.checkIdentifierFields(contextId, identifierFields, filteredPayload)
        .then((existingIdentifiers) => {
          if (existingIdentifiers) {
            setTimeout(() => {
              this.isSubmitting = false;
              this.notificationService.showError('Fehler: ID ist bereits vorhanden!', 4000);
            }, 1000);
          }
          else{
            // Save Task and Upload Files, optionally update existing Tridy if selected
            this.saveTask(contextId, workTaskData, existingTridy, print, workflow)
            .then(() => {
              this.onTaskSuccess();
            })
            .catch((error) => {
              this.onTaskError(error);
            });
          }
        })
        .catch((error) => {
          console.error('Error while checking for duplicate identifiers:', error);
        });
      }
      else{
        // Save Task and Upload Files, optionally update existing Tridy if selected
        this.saveTask(contextId, workTaskData, existingTridy, print, workflow)
        .then(() => {
          this.onTaskSuccess();
        })
        .catch((error) => {
          this.onTaskError(error);
        });
      }
    } else {
      console.error('Form is invalid. Please fill in all required fields.');
      this.notificationService.showError('Fehler: Formular ist ungültig. Bitte füllen Sie alle erforderlichen Felder aus!');
      this.form.markAllAsTouched();
    }
  }

  onTaskSuccess(){
    //console.log('Task and files uploaded successfully.');
    this.isSubmitting = false;
    this.isSubmitted = true;
    this.selectedTridy = false
    this.tridyFormGroup.reset();
    this.navigationService.triggerGlow();
    // Reset after 3 seconds
    setTimeout(() => {
      this.isSubmitted = false;
      this.form.reset();
      Object.keys(this.uploadedFiles).forEach((fieldKey) => {
        this.clearFile(fieldKey);
      });
    }, 3000);
  }

  onTaskError(error: any){
    console.error('Error uploading form and task data:', error);
    this.notificationService.showError('Fehler: Die Daten konnten nicht gesendet werden.');
    this.isError = true;
    // Reset after 5 seconds
    setTimeout(() => {
      this.isSubmitted = false;
      this.isError = false;
      this.form.reset();
      Object.keys(this.uploadedFiles).forEach((fieldKey) => {
        this.clearFile(fieldKey);
      });
    }, 5000);
    
  }  
  private async saveTask(contextId: string, workTaskData: any, tridyId?: string, print?: boolean, workflow?: boolean): Promise<void> {

/*     // Check for Identifier fields and abort if any are existing

        // Filter the payload based on template's fields with 'payloadKey'
        const filteredPayload = Object.entries(workTaskData.payload || {}).reduce(
        (result, [key, value]) => {
          const fieldInTemplate = workTaskData.template?.formSections
            ?.flatMap((section: any) => section.fields)
            ?.find((field: any) => field.payloadKey === key);
          if (fieldInTemplate) {
            result[key] = value;
          }
          return result;
        },
        {} as Record<string, any>
      );

      const identifierFields = workTaskData.template?.formSections
      ?.flatMap((section: any) => section.fields)
      ?.filter((field: any) => field.identifierKey && filteredPayload[field.payloadKey]);
  
      if (identifierFields) {
        this.existingIdentifiers = await this.dataService.checkIdentifierFields(contextId, identifierFields, filteredPayload);
        
        if(this.existingIdentifiers){
          console.log('Existing Identifiers!');
        }
        else{
          console.log('no existing Identifiers');
        }
      }
      else{
        console.log('no identifier fields found, continuing'); */
      

    // Update Tridy if tridyId is provided
    if (tridyId) {
      try {

        ///////////////////////////
        /* Update Existing Tridy */
        ///////////////////////////

        await this.dataService.updateExistingTridy(tridyId, workTaskData);
        // Save the work task first to generate the finished task ID
        const finishedTaskId = await this.dataService.saveWorkTask(contextId, { ...workTaskData, tridyId });
        const uploadedFilesUrls: {
          [key: string]: { primaryUrl: string; secondaryUrl: string; mimeType?: string; fileName?: string };
        } = {};
  
        // Upload to Firebase Storage (if any)
        for (const [fieldKey, file] of Object.entries(this.uploadedFiles)) {
          if (file) {
            const { primaryUrl, secondaryUrl } = await this.dataService.uploadFile(
              tridyId,
              finishedTaskId,
              fieldKey,
              file
            );
  
            // Extract MIME and file name
            const mimeType = file.type || undefined;
            const fileName = file.name || 'unknown.file';
            
            // Save URLs and metadata for the field
            uploadedFilesUrls[fieldKey] = { primaryUrl, secondaryUrl, mimeType, fileName };
          }
        }

        // Create a cleaned payload for files and other fields
        const cleanedPayload = Object.fromEntries(
          Object.entries(workTaskData.payload).map(([key, value]) => {
            if (uploadedFilesUrls[key]) {
              const fileMeta = (value || {}) as FileMeta;
              const { primaryUrl, secondaryUrl, mimeType, fileName } = uploadedFilesUrls[key];
  
              return [
                key,
                {
                  ...fileMeta,
                  primaryUrl,
                  secondaryUrl,
                  contentType: fileMeta.contentType || mimeType,
                  id: fileMeta.id,
                  ...(fileMeta.visibility ? { visibility: fileMeta.visibility } : {}),
                  ...(mimeType?.startsWith('image/') ? { mimeType } : {}), // Include mimeType only for images
                  fileName,
                },
              ];
            }
            return [key, value];
          })
        );
  
        // Update the Tridy document with the finished task ID and cleaned payload
        await this.dataService.updateExistingTridyWithWorkTask(tridyId, finishedTaskId, workTaskData, cleanedPayload);

        // DDL Case - print pdf if print action is true
        print ? this.helperService.printAction(tridyId, this.envId, contextId, workTaskData.template.contentType, workTaskData?.payload['ddl-id']) : null;
        // DDL Case - trigger start workflow if action is true
        workflow ? this.helperService.startWorkflow(
          tridyId,
          this.envId,
          contextId, 
          workTaskData,
          this.workflowParameters.workflowTemplateId,
          this.workflowParameters.synchronous) : null;


        //console.log('Files uploaded and task updated successfully.');
        this.notificationService.showSuccess('Aufgabe erfolgreich abgeschlossen.');
      } catch (error) {
        this.isError = true;
        this.isSubmitting = false;
        // Reset after 5 seconds
        setTimeout(() => {
          this.isSubmitted = false;
          this.isError = false;
          this.form.reset();
          Object.keys(this.uploadedFiles).forEach((fieldKey) => {
            this.clearFile(fieldKey);
          });
        }, 5000);
        console.error('Error uploading files or saving task data:', error);
        this.notificationService.showSuccess('Aufgabe konnte nicht abgeschlossen werden.');
        throw error;
      }

    }

    ///////////////////////////
    /* Create New Tridy */
    ///////////////////////////

    else{
      try {
        // Ensure Tridy ID is created before uploading files
        const tridyId = workTaskData.tridyId || (await this.dataService.createTridy(contextId, workTaskData, this.envId));
        // Save the work task first to generate the finished task ID
        const finishedTaskId = await this.dataService.saveWorkTask(contextId, { ...workTaskData, tridyId });
        const uploadedFilesUrls: {
          [key: string]: { primaryUrl: string; secondaryUrl: string; mimeType?: string; fileName?: string };
        } = {};
  
        // Upload to Firebase Storage (if any)
        for (const [fieldKey, file] of Object.entries(this.uploadedFiles)) {
          if (file) {
            const { primaryUrl, secondaryUrl } = await this.dataService.uploadFile(
              tridyId,
              finishedTaskId,
              fieldKey,
              file
            );
  
            // Extract MIME and file name
            const mimeType = file.type || undefined;
            const fileName = file.name || 'unknown.file';
  
            // Save URLs and metadata for the field
            uploadedFilesUrls[fieldKey] = { primaryUrl, secondaryUrl, mimeType, fileName };
          }
        }

        // Create a cleaned payload for files and other fields
        const cleanedPayload = Object.fromEntries(
          Object.entries(workTaskData.payload).map(([key, value]) => {
            if (uploadedFilesUrls[key]) {
              const fileMeta = (value || {}) as FileMeta;
              const { primaryUrl, secondaryUrl, mimeType, fileName } = uploadedFilesUrls[key];
  
              return [
                key,
                {
                  ...fileMeta,
                  primaryUrl,
                  secondaryUrl,
                  contentType: fileMeta.contentType || mimeType,
                  id: fileMeta.id,
                  ...(fileMeta.visibility ? { visibility: fileMeta.visibility } : {}),
                  ...(mimeType?.startsWith('image/') ? { mimeType } : {}), // Include mimeType only for images
                  fileName,
                },
              ];
            }
            return [key, value];
          })
        );
  
        // Update the Tridy document with the finished task ID and cleaned payload
        await this.dataService.updateNewTridyWithWorkTask(tridyId, finishedTaskId, workTaskData, cleanedPayload, this.importedMedia);

        // DDL Case - print pdf if print action is true
        print ? this.helperService.printAction(tridyId, this.envId, contextId, workTaskData.template.contentType, workTaskData?.payload['ddl-id']) : null;
        // DDL Case - trigger start workflow if action is true
        workflow ? this.helperService.startWorkflow(
          tridyId,
          this.envId,
          contextId, 
          workTaskData,
          this.workflowParameters.workflowTemplateId,
          this.workflowParameters.synchronous) : null;

        //console.log('Files uploaded and task saved successfully.');
        this.notificationService.showSuccess('Aufgabe erfolgreich abgeschlossen.');
      } catch (error) {
        this.isError = true;
        this.isSubmitting = false;
        // Reset after 5 seconds
        setTimeout(() => {
          this.isSubmitted = false;
          this.isError = false;
          this.form.reset();
          Object.keys(this.uploadedFiles).forEach((fieldKey) => {
            this.clearFile(fieldKey);
          });
          // also need to reset the docs
        }, 5000);
        console.error('Error uploading files or saving task data:', error);
        this.notificationService.showSuccess('Aufgabe konnte nicht abgeschlossen werden.');
        throw error;
      }
    }
 /*  } */
  }

}

export type FileMeta = {
  contentType?: string;
  id?: string;
  visibility?: string;
};