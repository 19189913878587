import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../material/material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-context-dialog',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  template: `
    <h1 mat-dialog-title>Kontoauswahl</h1>
    <div mat-dialog-content>
      <p>Bitte wähle ein Konto aus:</p>
      <mat-list>
        <mat-list-item *ngFor="let context of contextData" (click)="selectContext(context.id)">
          <span>{{ context.displayName }}</span>
        </mat-list-item>
      </mat-list>
    </div>
    <div mat-dialog-actions>
      <button mat-button style="color:var(--warmOrange);" (click)="closeDialog()">Abbrechen</button>
    </div>
  `,
  styles: [`
    .mat-mdc-list-item {
      cursor: pointer;
    }
    .mat-mdc-list-item:hover {
      background-color: var(--highlightOrange);
      & span{
        color: var(--warmOrange)!important;
    }
    }
  `]
})

export class ContextSelectionComponent implements OnInit {
  contextData: { id: string; displayName: string }[] = []; // Store context names and IDs
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public contextIds: string[],
    private dialogRef: MatDialogRef<ContextSelectionComponent>,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.fetchContextNames();
  }

  fetchContextNames(): void {
    this.contextIds.forEach((id) => {
      this.dataService.getContextData(id).subscribe(
        (data) => {
          if (data) {
            // Check if the ID already exists in contextData
            const exists = this.contextData.some((context) => context.id === id);
            if (!exists) {
              this.contextData.push({ id, displayName: data.displayName });
            }
          }
        },
        (error) => {
          console.error(`Error fetching context data for ID ${id}:`, error);
        }
      );
    });
  }
  

  selectContext(contextId: string): void {
    this.dialogRef.close(contextId);
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }


}
