<div class="main-content-wrapper">
    <div class="p-4" style="overflow: hidden;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                        <div class="my-4">
                            <h2>Hallo {{userDisplayName}},</h2>
                            <p>Willkommen auf Ihrem Dashboard.</p>
                        </div>
                </div>
            </div>
        <!-- <div class="row">
                <div class="col-md-4">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Offene Aufgaben</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>Sie haben 5 offene Aufgaben</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-4">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Überfällige Aufgaben</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>Sie haben 2 überfällige Aufgaben</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-4">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Kommende Ereignisse</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>Sie haben 2 kommende Ereignisse</p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div> -->
        </div>
    </div>
</div>
