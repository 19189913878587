<div class="form-wrapper p-4">
  @if(taskData){

    @if(isLoading) {
      <div class="spinner">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
        <p class="mt-4">Laden...</p>
      </div>
    }
  
    @if(!isLoading) {

      <h4 style="color: var(--warmOrange);">{{taskData.title}}:</h4>
      <div class="mb-2">
        Aufgabentyp:
        @if(taskData?.taskType === 'create'){ Neuerstellung}
        @if(taskData?.taskType === 'update'){ Bearbeitung}
      </div>

      @if(!isSubmitting && !isSubmitted && !isError) {

        <mat-stepper orientation="vertical" [linear]="true" #stepper class="mt-3 pb-4">

          <!-- OPTIONAL Step 1: Choosing Tridy -->
          @if(showTridySelection) {
          <mat-step [stepControl]="tridyFormGroup">

            <ng-template matStepLabel>Tridy Auswahl</ng-template>

            <form [formGroup]="tridyFormGroup">

              @if(!selectedTridy) {
                <p>Bitte wähle ein Tridy aus, um die Aufgabe zu bearbeiten.</p>
                <button class="submit-button mt-2" mat-flat-button (click)="openTridySelectionDialog()">Tridy auswählen</button>
              }
              
              @if(selectedTridy) {
                <mat-card class="gradient-bg p-3 mb-4">
                  <!-- <mat-card-header>
                    <h5>Ausgewählt:</h5>
                  </mat-card-header> -->
                  <mat-card-content class="my-2">
                    @if (selectedTridy.payload?.['ddl-id']) {
                      <strong>DDL-ID:</strong> {{ selectedTridy.payload?.['ddl-id'] }}<br/>
                    }
                    <strong>ID:</strong> {{ selectedTridy.id }}<br />
                    @if(selectedTridy?.contentType) {
                      <strong>Inhaltstyp:</strong> {{ selectedTridy.contentType }}<br />
                    }
                    <strong>Erstellt:</strong> {{ selectedTridy.creationDate.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}<br />
                    <strong>Letzte Änderung:</strong> {{ selectedTridy.timeStamp.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}
                  </mat-card-content>
                  <mat-card-actions>
                    <button class="stepper-button" mat-button (click)="openTridySelectionDialog()">Auswahl ändern</button>
                  </mat-card-actions>
                </mat-card>
              }                

              <div class="mt-2">
                <button class="stepper-button" mat-button matStepperNext [disabled]="!tridyFormGroup.valid">Weiter</button>
              </div>
            </form>
          </mat-step>
          }

          <!-- Step 2: The Form -->
          <mat-step [stepControl]="form">
            <ng-template matStepLabel>Daten eingeben</ng-template>
            <form [formGroup]="form" style="height: 100%;" class="mt-2 justify-content-between d-flex flex-column">

              <div style="height: 95%;border:none;box-shadow: none;">
                <div style="height: 100%;overflow: auto;">
                  <div class="form-content">
                    <div *ngFor="let section of taskData.formSections; let i = index" [formGroup]="getSectionGroup(i)">
                      <div class="section-title py-3">{{ section.title }}</div>

                      <ng-container *ngFor="let field of section.fields">

                        <!-- 
                        <div *ngIf="field.type === 'barcode'" class="info-box mb-3 p-3">
                          <mat-icon>info</mat-icon>
                          The barcode scan is currently not supported. Instead, you can enter the barcode as text.
                        </div> -->

                        @if(field.type === 'tridy'){

                          <!-- select tridy from the list? based on payload value? -->
                          <div class="my-1 w-100">
                            <em style="color:red">Tridy Feld wird zuerzeit nicht unterstützt</em>
                            <!-- Tridy ID mit Autocomplete oder open Dialog -->
                          </div>
                        }

                        @if(field.type === 'barcode'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <input matInput [formControlName]="field.key" />
                              <mat-icon matSuffix matTooltip="Der Barcode-Scan wird derzeit im Browser nicht unterstützt. Gebe stattdessen den Barcode als Text ein.">help</mat-icon>
                          </mat-form-field>
                        }

                        @if(field.type === 'dateTime'){
                          <div class="my-1 d-flex flex-row">
                            <div class="pe-2 flex-grow-1">
                                <div class="input-group">
                                  <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>{{ field.title }}</mat-label>
                                    <input
                                      matInput
                                      [matDatepicker]="picker"
                                      [formControlName]="field.key"
                                      [placeholder]="'TT.MM.JJJJ'" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                  </mat-form-field>
                                </div>
                            </div>
                            <div class="ps-2 flex-grow-1">
                                <div class="input-group">
                                  <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Zeit</mat-label>
                                    <input
                                      matInput
                                      type="time"
                                      [formControlName]="field.key + '_time'"
                                      [placeholder]="'HH:mm'" />
                                  </mat-form-field>
                                </div>
                            </div>
                        </div>
                        }

                        @if(field.type === 'signature'){
                          <div class="my-1 w-100">
                            <em style="color:red">Signaturfeld wird zuerzeit nicht unterstützt</em>
                          </div>
                        }

                        @if(field.type === 'showImage'){
                          <div class="my-1 w-100">
                            <span>{{ field | json }}</span>
                          </div>
                        }

                        @if(field.type === 'currency'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <input matInput [formControlName]="field.key" type="number" />
                          </mat-form-field>
                        }

                        @if(field.type === 'text'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <input matInput [formControlName]="field.key" />
                          </mat-form-field>
                        }

                        @if(field.type === 'displaytext'){
                          <div class="my-1 w-100">
                            <span>{{ field | json }}</span>
                          </div>
                        }

                        @if(field.type === 'weight'){
                          <mat-form-field class="weight my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <input matInput placeholder="0" [formControlName]="field.key" type="number" />
                            <span matTextSuffix>KG</span>
                          </mat-form-field>
                        }

                        @if(field.type === 'decimal'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <input matInput [formControlName]="field.key" type="number" />
                          </mat-form-field>
                        }

                        @if(field.type === 'number'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <input matInput [formControlName]="field.key" type="number" />
                          </mat-form-field>
                        }

                        @if(field.type === 'longText'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <textarea matInput [formControlName]="field.key"></textarea>
                          </mat-form-field>
                        }

                        @if(field.type === 'options'){
                          <mat-form-field class="my-1 w-100">
                            <mat-label>{{ field.title }}</mat-label>
                            <mat-select [formControlName]="field.key">
                              <mat-option *ngFor="let option of field.options" [value]="option">
                                {{ option }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        }

                        @if(field.type === 'boolean'){
                          <mat-slide-toggle class="mt-2 mb-4 w-100" [formControlName]="field.key">
                            <span class="ms-2">{{ field.title }}</span>
                          </mat-slide-toggle>
                        }

                        @if(field.type === 'document'){
                          <div class="file-upload-container">
                            @if(selectedTridy?.payload?.[field.key]?.fileName){
                              <div class="pb-2 existing-upload">Bereits hochgeladen: {{selectedTridy?.payload?.[field.key]?.fileName}}</div>
                            }
                            <mat-form-field appearance="fill" class="file-upload-field">
                              <mat-label>{{ field.title }}</mat-label>
                              <input
                                type="file"
                                #fileInput
                                (change)="onFileSelected($event, field.key)"
                                class="file-input"
                                accept=".jpg, .jpeg, .png, .pdf, .webp"
                              />
                              <input
                                matInput
                                readonly
                                [formControlName]="field.key"
                                [value]="uploadedFiles[field.key]?.name || 'Keine Datei ausgewählt'"
                                placeholder="Datei auswählen"
                              />
                              <button
                                *ngIf="!uploadedFiles[field.key]"
                                mat-icon-button
                                matSuffix
                                (click)="fileInput.click()"
                                aria-label="Select file"
                                matTooltip="Datei auswählen"
                              >
                                <mat-icon>attach_file</mat-icon>
                              </button>
                              <button
                                mat-icon-button
                                matSuffix
                                *ngIf="uploadedFiles[field.key]"
                                (click)="clearFile(field.key)"
                                aria-label="Clear file"
                                matTooltip="Datei löschen"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                              <mat-error *ngIf="getSectionGroup(i).get(field.key)?.hasError('required')">
                                {{ field.title }} ist Pflichtfeld.
                              </mat-error>
                            </mat-form-field>
                          </div>
                        }

                      </ng-container>
                    </div>

                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                @if(taskData?.taskType === 'update') {
                  <button class="stepper-button" mat-button matStepperPrevious>Zurück</button>
                }
                <button  class="stepper-button" mat-button matStepperNext [disabled]="!form.valid">Weiter</button>
              </div>

            </form>
          </mat-step>

          <!-- Step 3: Review and Submit -->
          <mat-step>
            <ng-template matStepLabel>Prüfen und absenden</ng-template>

              @if(selectedTridy) {
                <div class="my-2">
                  <h6>Ausgewählte Tridy:</h6>
                  <!-- needs to be updated on ddl-id -->
                  @if(selectedTridy.payload?.['ddl-id']) {
                    <div>DDL-ID: {{ selectedTridy.payload?.['ddl-id'] }}</div>
                  }
                  <div>ID: {{ tridyFormGroup.get('tridySelection')?.value.id }}</div>
                </div>
              }

              <div *ngFor="let section of taskData.formSections; let i = index" [formGroup]="getSectionGroup(i)">
              
                <div class="my-3">
                  <div class="section-title pb-3">
                    <strong>{{ section.title }}</strong>
                  </div>
                  <mat-card class="gradient-bg my-2 p-3">
                    <table class="table m-0">
                      <tbody>
                        <tr>
                          <th style="width: 33.3%;">Eingabefeld</th>
                          @if(taskData?.taskType === 'update') {
                          <th style="width: 33.3%;color: gray;">Alter Wert</th>
                          <th></th>
                          }
                          <th style="width: 33.3%;">Neuer Wert</th>
                        </tr>
                        <ng-container *ngFor="let field of section.fields">
                          <tr>
                            <td style="width: 33.3%;">{{ field.title }}</td>
                            @if(taskData?.taskType === 'update') {
                            <td style="width: 16.66%;color:gray;">
                              @if(field.type === 'document' && selectedTridy?.payload?.[field.key]?.fileName) {
                                {{selectedTridy?.payload?.[field.key]?.fileName}}
                              }
                              @else{
                                {{ selectedTridy?.payload?.[field.key] }}
                              }
                            </td>
                            
                            <td style="width: 16.66%;">
                              <mat-icon *ngIf="getSectionGroup(i).get(field.key)?.value || uploadedFiles[field.key]?.name || selectedTridy?.payload?.[field.key]?.fileName">arrow_right_alt</mat-icon>
                            </td>
                            }
                            <td style="width: 33.3%;">
                              {{ getSectionGroup(i).get(field.key)?.value }}
                              @if(field.type === 'document'){
                                @if(uploadedFiles[field.key]?.name) {
                                  {{uploadedFiles[field.key]?.name}}
                                }
                                @else if(selectedTridy?.payload?.[field.key]?.fileName) {
                                  {{selectedTridy?.payload?.[field.key]?.fileName}}
                                  <!-- gleich -->
                                }
                                @else {
                                }
                              }
  
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </mat-card>

                </div>

              </div>

            <div class="d-flex justify-content-between mt-4">
              <button mat-button class="stepper-button" matStepperPrevious>Zurück</button>
              <button mat-button class="submit-button" (click)="onSubmit()" [disabled]="!form.valid"><mat-icon>send</mat-icon> Absenden</button>
            </div>
          </mat-step>

        </mat-stepper>
    
      }

      @if(isSubmitting) {
        <div class="spinner">
          <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
          <p class="mt-4">Aufgabe in Bearbeitung...</p>
        </div>
      }

      @if(isSubmitted) {
        <div class="spinner">
          <mat-icon class="task-done">task_alt</mat-icon>
          <p class="mt-4">Aufgabe erfolgreich abgeschlossen!</p>
        </div>
      }

      @if(isError) {
        <div class="spinner">
          <mat-icon class="task-done">sentiment_very_dissatisfied</mat-icon>
          <p class="mt-4 text-center">Beim Absenden der Aufgabe ist ein Fehler aufgetreten.<br>Bitte versuche es erneut oder kontaktiere den Administrator.</p>
        </div>
      }

      <!-- erorr handling missing -->

    }

  }
  @else {
    <div style="overflow: scroll;height: 99%;">
      <div class="d-flex justify-content-center" style="width:100%;height:100%;">
        <p style="align-self:center;">Nichts ausgewählt</p>
      </div>
    </div>
  }

</div>
