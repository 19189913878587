<div class="container-fluid login-page" fxLayout="column" fxLayoutAlign="center center">

    <!--     <div class="position-top">
    
            <div class="d-flex flex-row align-self-end justify-content-end flex-wrap m-lg-2">
                <button mat-button [matMenuTriggerFor]="language" style="color:#fff;margin-bottom:10px;">
                    <mat-icon class="material-symbols-outlined">
                        arrow_drop_down
                    </mat-icon>
                    <span *ngIf="languageFlag === 'gb'">
                        <span class="fi fi-gb dd-menu-image"></span> English
                    </span>
                    <span *ngIf="languageFlag === 'de'">
                        <span class="fi fi-de dd-menu-image"></span> Deutsch
                    </span>
                    <span *ngIf="languageFlag === 'es'">
                        <span class="fi fi-es dd-menu-image"></span> Español
                    </span>
                    
                </button>
                <mat-menu #language="matMenu">
                    <button mat-menu-item (click)="useLanguage('en')"><span class="fi fi-gb dd-menu-image"></span>  English </button>
                    <button mat-menu-item (click)="useLanguage('de')"><span class="fi fi-de dd-menu-image"></span>  Deutsch </button>
                    <button mat-menu-item (click)="useLanguage('es')"><span class="fi fi-es dd-menu-image"></span>  Español </button>
                </mat-menu>
            </div>
        </div> -->
    
        <div class="login-card-wrapper">
            <div class="login-card p-4 mat-elevation-z8">
                <div class="login-logo mt-3">
                    <img src="narravero_logo.webp" id="logo" class="logo-image" alt="Narravero Logo">
                </div>
    
                @if (!isLoading) {
                    <div class="d-flex flex-column px-3 align-items-center justify-content-center flex-wrap form-section">
    
                        <form class="" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        
                            <mat-form-field id="email-field" appearance="outline" class="w-100 mt-2">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Email" formControlName="email" type="email" id="emailAdress" required>
                                
                                @if (loginForm.get('email')?.hasError('required')) {
                                    <mat-error>Email is required.</mat-error>
                                }
                                @if (loginForm.get('email')?.hasError('email')) {
                                    <mat-error>Please enter a valid email address.</mat-error>
                                }
                                
                            </mat-form-field>
            
                            <mat-form-field id="password-field" appearance="outline" class="w-100">
                                <mat-label>Passwort</mat-label>
                                <input matInput formControlName="password" placeholder="Passwort" id="passwordValue" [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </button>

                                @if (loginForm.get('password')?.hasError('required')) {
                                    <mat-error>Password is required.</mat-error>
                                }

                            </mat-form-field>
                            
                            @if (badLogin) {
                                <div style="padding: 10px; background-color: var(--pureWhite); margin-bottom:10px; color:var(--accentGlobal); border-color: var(--accentGlobal);text-align:center; font-weight: bold; border: 1px solid var(--accentGlobal); border-radius:5px;">    
                                    Login Fehler
                                </div>
                            }
        
        
                            <button
                            id="login-button"
                            mat-flat-button
                            color="primary"
                            style="background-color:var(--warmOrange);"
                            [disabled]="loginForm.invalid"
                            class="w-100 mb-2"
                          >
                            Einloggen
                          </button>
                            
                        </form>
                    
                    </div>
                }
    
                @if (!isLoading) {
                    <div class="px-3" matTooltip="Zurzeit nicht möglich. Bitte kontaktieren Sie Ihren Administrator.">
                        <!-- (click)="callForgotPassword();" -->
                        <button disabled mat-button id="forgot-password-button" class="w-100" >Passwort vergessen?</button>
                    </div>
                }
    
                @if(isLoading) {
                    <div class="login-spinner">
                        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
                    </div>
                }
    
            </div>
            <div class="dev-note mobile-text-small"> {{buildEnvironment}} - {{version}} ( {{buildNumber}} )</div>
        </div>
    
        <div class="position-bottom">
            <div class="p-4 w-100">
                <div class="d-flex flex-row justify-content-between flex-wrap">
                    <div class="footer-links mobile-text-small">
                        <span ><a class="text-decoration-none" target="_blank" href="https://www.narravero.com/impressum">Impressum</a></span> 
                        <span class="divider">   |  </span> 
                        <span> <a class="text-decoration-none" target="_blank" href ="https://www.narravero.com/datenschutz">Datenschutz</a></span>
                    </div>
                </div>
            </div>
        </div>
    
    </div>


