import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { getAuth, onAuthStateChanged } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const auth = getAuth();

      onAuthStateChanged(auth, (user) => {
        if (user && user.uid) {
      
          if (state.url === '/login') {
            this.router.navigate(['/dashboard']); 
            observer.next(false);
          } else {
            observer.next(true);
          }
        } else {
          
          if (state.url !== '/login') {
            this.router.navigate(['/login']);
            observer.next(false);
          } else {
            observer.next(true);
          }
        }

        observer.complete();
      });
    });
  }
}