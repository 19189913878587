import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../material/material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tridy-dialog',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  template: `

    <h1 mat-dialog-title>Tridy Auswahl</h1>
    <div mat-dialog-content style="overflow:hidden;">
      <div style="position:relative;">

      <p>Bitte wähle ein Tridy aus:</p>
        <div class="task-toolbar d-flex justify-content-between align-items-center">
          
          <mat-form-field appearance="fill" class="mx-3 w-100">
            <input
              matInput
              placeholder="Tridys durchsuchen"
              matTooltip="Suche nach ID oder spezifischen Daten aus dem Payload."
              [(ngModel)]="searchTerm"
              (input)="filterTridys()" />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
          <!-- 
            <div class="mb-4 mx-3">
              <button disabled mat-icon-button [matMenuTriggerFor]="filterMenu">
                <mat-icon>tune</mat-icon>
              </button>
              <mat-menu #filterMenu="matMenu">
                <button mat-menu-item>
                  <mat-slide-toggle>Gruppen anzeigen</mat-slide-toggle>
                </button>
                filter for types (transform, edit, create)
                custom filter by
              </mat-menu>
            </div>
          -->
        </div>
      </div>

      <section class="my-2" style="overflow: auto;height: 440px;">

        <div class="tridy-list">
          @if (tridys.length > 0) {
            <mat-list style="margin-bottom: 30px">
              <!--  @for (template of workTaskTemplates; track template.id) { -->
              @for (tridy of tridys; track tridy.id) {
                <mat-list-item
                    (click)="selectTridy(tridy.id)"
                  matTooltipShowDelay="1500"
                  matTooltip="Inhaltstyp: {{ tridy.contentType }} &nbsp; Tridy ID: {{ tridy.id }}"
                >
              <!-- <mat-list-item (click)="selectContext(context.id)"> -->
                <div class="my-4">
                  <!-- in future show titleKey (based on content type or tracer template?) -->
                  <strong>{{ tridy.id }}</strong>
                  @if (tridy.payload?.['ddl-id']) {
                    <div class="ddl-info"><strong>DDL-ID:</strong> {{ tridy.payload?.['ddl-id'] }}</div>
                  }
                  @if (tridy.creationDate) {
                    <div class="details d-flex align-items-center"><mat-icon>access_time</mat-icon> {{ tridy.creationDate.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}</div>
                  }
                  @if (tridy.timeStamp) {
                    <div class="details d-flex align-items-center"><mat-icon>update</mat-icon> {{ tridy.timeStamp.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}</div>
                  }
                </div>
              </mat-list-item>
              }
            </mat-list>
          }
        </div>
      </section>

    </div>
    <div mat-dialog-actions>
      <button mat-button style="color:var(--warmOrange);" (click)="closeDialog()">Abbrechen</button>
    </div>
  `,
  styles: [`
    .mat-mdc-list-item {
      cursor: pointer;
      strong{
        color: var(--narraveroBlue);
      }
    }
    .mat-mdc-list-item:hover {
      background-color: var(--highlightOrange);
      strong{
        color: var(--warmOrange)!important;
      }
    }
    .tridy-list {
      .mat-mdc-list-item {
        height:96px!important;
      }
      .details{
        font-size:12px;
        color:gray;
        height:14px;
        margin-top:4px;
        mat-icon{
          font-size:14px;
          height:14px;
        }
      }
      .ddl-info{
        font-size:13px;
        color:gray;
      }
    }
  `]
})

export class TridySelectionComponent implements OnInit {
  originalTridys: any[] = []; // Store the original list of tridys
  tridys: any[] = []; // This will be the filtered list shown in the UI
  searchTerm: string = '';
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any[],
    private dialogRef: MatDialogRef<TridySelectionComponent>
  ) {}

  ngOnInit(): void {
    // Initialize both the original and working copies of tridys
    this.originalTridys = [...this.dialogData]; // Clone to keep it immutable
  
    this.tridys = [...this.dialogData].sort((a, b) => {
      // Convert Firebase Timestamps to JavaScript Date
      const timeA = a.timeStamp?.toDate() || 0;
      const timeB = b.timeStamp?.toDate() || 0;
      // Compare the time values
      return timeB.getTime() - timeA.getTime(); // Desc
    });
  
    //console.log("Sorted Tridys:", this.tridys);
  }  

  filterTridys(): void {
    const term = this.searchTerm.toLowerCase();

    if (term) {
      // Filter the original list based on the search term
      this.tridys = this.originalTridys.filter((tridy) => {
        return (
          tridy.id?.toLowerCase().includes(term) || 
          Object.values(tridy.payload || {}).some((value: any) =>
            String(value).toLowerCase().includes(term)
          )
        );
      });
    } else {
      // Reset to the original list when the search term is empty
      this.tridys = [...this.originalTridys];
    }
  }
  selectTridy(tridyId: string): void {
    this.dialogRef.close(tridyId);
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }


}
