import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { httpsCallable, Functions } from '@angular/fire/functions';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private dataService: DataService,
    private functions: Functions
  ) {}

  ///// FORM HELPERS

  isDateTimeField(key: string, section: any): boolean {
    return section[key + '_time'] !== undefined;
  }
  
  cleanFieldData(value: any): any {
    // Unused
    if (value === null || value === undefined) {
      return undefined; // Replace null/undefined with undefined to omit the field
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      const cleanedObject: any = {};
      Object.keys(value).forEach((key) => {
        if (value[key] !== null && value[key] !== undefined) {
          cleanedObject[key] = this.cleanFieldData(value[key]); // Recursively clean nested objects
        }
      });
      return cleanedObject;
    }
    return value; // Return primitive values as-is
  }

  cleanFormData(data: any, preserveKeys: string[] = []): any {
    if (Array.isArray(data)) {
      return data
        .map((item) => this.cleanFormData(item, preserveKeys))
        .filter((item) => item !== null && item !== undefined);
    } else if (typeof data === 'object' && data !== null) {
      const cleanedObject: any = {};
      Object.keys(data).forEach((key) => {
        const value = data[key];

        if (value === null && preserveKeys.includes(key)) {
          cleanedObject[key] = null;
        } else if (value !== null && value !== undefined) {
          cleanedObject[key] = this.cleanFormData(value, preserveKeys); // Recursively clean
        }
      });
      return cleanedObject;
    }
    return data; // Return primitive values as-is
  }

  // Get field ID from the template
  getFieldIdFromTemplate(taskData: any, sectionIndex: number, key: string): string | null {
    const section = taskData.formSections[sectionIndex];
    if (!section) return null;
    const field = section.fields.find((field: any) => field.key === key);
    //console.log('Field ID:', field ? field.id : null);
    return field ? field.id : null;
  }

  formatDateGerman(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    };
    return new Intl.DateTimeFormat('de-DE', options).format(date);
  }

  ///// HANDLING TASK ACTIONS

  async printAction(tridyId: string, env: string, contextId: string, contentType: string, ddlId?: string): Promise<void> {
    try {
      const tridyData = await this.dataService.createTridyURL(tridyId, env, contentType, contextId);
      
      
      if (ddlId) {
        const qrCodeDataUrl = await QRCode.toDataURL(tridyData, { width: 1000 });
        // Save as PNG
        const img = new Image();
        img.src = qrCodeDataUrl;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = 1000;
          canvas.height = 1000;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 50, 0, 900, 900);
            ctx.font = '70px Arial';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'black';
            ctx.fillText(`DDL-ID: ${ddlId}`, canvas.width / 2, 930);
            canvas.toBlob((blob) => {
              if (blob) {
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `QRCode_${ddlId}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
              }
            });
          }
        };
      } else {
        const qrCodeDataUrl = await QRCode.toDataURL(tridyData, { width: 300 });
        // Save as PDF
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: [50, 50],
        });
        doc.addImage(qrCodeDataUrl, 'PNG', 3, 1, 44, 44);
        const filename = `QRCode_${tridyId}.pdf`;
        doc.save(filename);

        // Use a blob to open the print dialog
        const pdfBlob = doc.output('blob');
        const blobUrl = URL.createObjectURL(pdfBlob);
        const printWindow = window.open(blobUrl);
      
        if (printWindow) {
          printWindow.addEventListener('load', () => {
            printWindow.print();
            URL.revokeObjectURL(blobUrl); // Clean up memory after printing
          });
        } else {
          console.error('Unable to open print window.');
        }
      }
    } catch (error) {
      console.error('Fehler beim Erstellen des QR-Codes. Details:', error);
    }
  }

  async startWorkflow(tridyId: string, env: string, contextId: string, workTaskData: any, workflowTemplateId: string, synchronous: boolean) {

    try{
      await new Promise((resolve) => setTimeout(resolve, 300));
      const tridyData = await this.dataService.createTridyURL(tridyId, env, workTaskData.template.contentType, contextId);

      const startWorkflow = httpsCallable(this.functions, 'workflow-startWorkflow');

      const input = {
        ...workTaskData.payload,
        tridyId,
        tridyData,
      };
  
      const requestPayload = {
        workflowTemplateId,
        contextId,
        input,
        synchronous,
      };
    
      startWorkflow(requestPayload)
        .then((result) => {
          console.log('Workflow started successfully:', result);
        })
        .catch((error) => {
          console.error('Error triggering workflow:', error);
        });
    } catch (error) {
      console.error('Error starting workflow. Details:', error);
    }

  }

  async openQRCode(passURL: string): Promise<void> {
    //console.log('Opening QR Code:', passURL);
    const qrCodeDataUrl = await QRCode.toDataURL(passURL, { width: 300 });
    const printWindow = window.open('');
    if (printWindow) {
      printWindow.document.write(
        `<img src="${qrCodeDataUrl}" style="width: 300px; height: 300px;"/>`
      );
      printWindow.addEventListener('load', () => {
        printWindow.print();
      });
    } else {
      console.error('Unable to open print window.');
    }
  }
}