import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, User, onAuthStateChanged, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private currentUserSubject = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.currentUserSubject.asObservable();
  currentUser:any
  linkedUser:any
  
  constructor(
    private router: Router,
    private auth: Auth,
    private notificationService: NotificationService) {

    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.user$ = this.currentUserSubject.asObservable();

    onAuthStateChanged(this.auth, (user) => {
      //console.log('onAuthStateChanged user:', user);
      this.currentUserSubject.next(user);
    });
  }

async signIn(email: string, password: string): Promise<void> {
  try {
    const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
    //console.log('User signed in successfully:', userCredential.user);
    this.router.navigate(['/dashboard']);
  } catch (error) {
    console.error('Error during sign-in:', error);
    this.notificationService.showError('Fehler: Anmeldung fehlgeschlagen. Versuche es später erneut.');
    throw error;
  }
}

async signOut(): Promise<void> {
  try {
    await signOut(this.auth);
    //console.log('User signed out successfully');
    this.router.navigate(['/login']);
  } catch (error) {
    console.error('Error during sign-out:', error);
    this.notificationService.showError('Fehler: Abmeldung fehlgeschlagen.');
  }
}

  isAuthenticated(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => !!user),
      shareReplay(1) // Cache the result to avoid redundant calculations
    );
  }
    
}
