@if (authService.isAuthenticated() | async) {
  <main class="main">
    <!-- Toolbar with toggle trigger -->
    <app-toolbar (toggleSidebar)="toggleSidebar()"></app-toolbar>
  
    <mat-sidenav-container class="main-container">
      <!-- Sidebar navigation with sidenav control -->
      <mat-sidenav #sidenav mode="side" [(opened)]="opened">
        <app-side-navigation></app-side-navigation>
      </mat-sidenav>
      <!-- Main content area -->
      <mat-sidenav-content class="p-0">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </main>
}
@else {
  <router-outlet></router-outlet>
}
