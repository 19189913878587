import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialModule } from '../../material/material.module';
import { environment } from '../../../environments/environment';
import { build, version } from '../../../../src/buildVersion';

@Component({
  selector: 'app-log-in',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule],
  templateUrl: './log-in.component.html',
  styleUrl: './log-in.component.scss'
})
export class LogInComponent implements OnInit {

  loginForm!:FormGroup
  logged:any=false
  badLogin=false
  currentUser$:any
  environmentName=environment.firebase
  contextId:any
  languageSelected:any
  isLoading:boolean=false
  languageFlag:any
  hide = true
  private auth: any;
  errorMessage: string | null = null;
  hidePassword = true;
  buildEnvironment?:string
  version=version
  buildNumber=build


  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
    ){} 


  ngOnInit() {

    this.isLoading=false
    this.loginForm = this.fb.group({
      email:['',[Validators.required, Validators.email]],
      password:[,[Validators.required]]
    })
    this.loginForm = this.fb.group({
      email:['',[Validators.required, Validators.email]],
      password:[,[Validators.required]]
    })

    this.loginForm.valueChanges.subscribe( (value:any) => {
      this.badLogin=false
      if (this.loginForm.hasError('invalidCredentials')) {
        console.log(this.loginForm.hasError('invalidCredentials'))
        const message = "Wrong Data"
        console.log(message)
        this.loginForm.setErrors({invalidCredentials: false})
      }
    })

  if(this.environmentName.projectId=="narravero-eu-dev"){
      this.buildEnvironment="Narravero DEV "
    }
  if(this.environmentName.projectId=="narravero-eu-prod"){
    this.buildEnvironment="Narravero "
  }
  if(this.environmentName.projectId=="narravero-eu-test"){
    this.buildEnvironment="Narravero TEST "
  }
  }

  async onSubmit(): Promise<void> {
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;
    const { email, password } = this.loginForm.value;

    try {

      await this.authService.signIn(email, password);
  
      // Fetch user data and context
      this.authService.user$.subscribe((user) => {
        if (user) {

          const savedContextId = this.dataService.getLocalContextId();
  
          if (savedContextId) {
            //console.log('Using saved Context ID:', savedContextId);
            this.dataService.contextIdChange$.next(savedContextId);
            this.router.navigate(['/dashboard']);
          } else {
            // Fetch context IDs from Firestore
            this.dataService.getDataUsersFirestore().subscribe((users) => {
              const matchedUser = users.find((u: any) => u.id === user.uid);
  
              if (matchedUser?.contextIds?.length > 0) {
                // Save and set the first context ID
                const selectedContextId = matchedUser.contextIds[0];
                //console.log('No saved Context ID. Using first:', selectedContextId);
                this.dataService.saveContextId(selectedContextId);
                this.dataService.contextIdChange$.next(selectedContextId);
  
                this.router.navigate(['/dashboard']);
              } else {
                console.error('No contexts available for the user.');
              }
            });
          }
        }
      });
    } catch (error: any) {
      this.errorMessage = this.getErrorMessage(error.code);
    } finally {
      this.isLoading = false;
    }
  }
  private getErrorMessage(errorCode: string): string {
    switch (errorCode) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'Invalid email or password.';
      case 'auth/too-many-requests':
        return 'Too many unsuccessful login attempts. Please try again later.';
      default:
        return 'An error occurred during sign-in. Please try again.';
    }
  }

}
