import { Injectable } from '@angular/core';
import { Firestore, getDoc, arrayUnion, Timestamp, runTransaction ,setDoc, getDocs, serverTimestamp, collection, collectionData, doc, docData, addDoc } from '@angular/fire/firestore';
import { NetworkConstants } from '../../../src/network.constants';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkTaskTemplate } from '../models/work-task-template.model';
import { query, where } from '@angular/fire/firestore';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  userAccount$: any;
  contextAccount$: any;
  userRoles$: any = [];
  userRolesRights$: any = {};
  currentUser$: any;
  uid$: any;
  contextIds$: any = [];
  contextId$: any;
  preReloadContextId$: any;
  settingsUserData$: any;
  workTaskTemplates: any[] = [];
  finishedTasks: any[] = [];
  tagHostName: string = "";
  allTridys$: any[] = [];
  allTridys: void | any = [];

  private readonly CONTEXT_ID_KEY = 'selectedContextId';
  private taskSubject = new BehaviorSubject<any | null>(null);

  contextIdChange$: Subject<string> = new Subject<string>();

  constructor(private firestore: Firestore, private storage: Storage, private notificationService: NotificationService) {}

  getDataUsersFirestore(): Observable<any[]> {
    const usersCollection = collection(this.firestore, NetworkConstants.COLLECTION_USERS);
    return collectionData(usersCollection, { idField: 'id' });
  }

  getContextIdOfUser(userId: string): void {
    this.getDataUsersFirestore().subscribe(
        (users) => {
            const user = users.find((u: any) => u.id === userId); // Find user by ID
            if (user) {
                if (user.contextIds && user.contextIds.length > 0) {
                    this.contextId$ = user.contextId || user.contextIds[0]; // Default to contextId or the first contextId
                    //console.log('All Context IDs:', user.contextIds);
                } else {
                    // console.warn('No context IDs found for user:', id);
                }
                    // this.contextIdChange$.next(this.contextId$); // Notify observers
            } else {
                //console.warn('No user found with the given ID:', id);
            }
        },
        (error) => {
            console.error('Error fetching user context ID:', error);
            this.notificationService.showError('Fehler: Benutzerdaten konnten nicht geladen werden.');
        }
    );
  }

  getContextData(contextId: string): Observable<any> {
    const docRef = doc(this.firestore, NetworkConstants.COLLECTION_CONTEXTS, contextId);
    return docData(docRef);
  }

  getTridys(contextId: string, contentTypeId: string): Promise<any[]> {
    return getDocs(
      query(
        collection(this.firestore, NetworkConstants.COLLECTION_TRIDYS),
        where("contextId", "==", contextId),
        where("contentType", "==", contentTypeId)
      )
    ).then((snapshot) =>
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    );
  }

  getWorkTaskTemplateData(contextId: string): Observable<WorkTaskTemplate[]> {
    const templatesCollection = collection(
        this.firestore,
        `${NetworkConstants.COLLECTION_CONTEXTS}/${contextId}/WorkTaskTemplates`
    );
    return collectionData(templatesCollection, { idField: 'id' }).pipe(
        map((templates: WorkTaskTemplate[]) => 
            //filter out transform types
            templates.filter((template: WorkTaskTemplate) => template.taskType !== 'transform')
        )
    );
  }

  setWorkTaskTemplates(templates: any[]): void {
    this.workTaskTemplates = templates;
  }

  getTaskById(taskId: string): any {
    return this.workTaskTemplates.find((task) => task.id === taskId) || null;
  }

  selectTask(task: any): void {
    this.taskSubject.next(task);
  }

  getSelectedTask(): Observable<any> {
    return this.taskSubject.asObservable();
  }

  // SUBMIT TASK SERVICES
  
  // Upload a file to Firebase Storage and get the download URL
  async uploadFile(tridyId: string, finishedTaskId: string, key: string, file: File): Promise<{ primaryUrl: string; secondaryUrl: string }> {
    try {
      // Define storage paths
      const primaryPath = `passes/${tridyId}/${file.name}`;
      const secondaryPath = `passes/${tridyId}/wt-${finishedTaskId}/WT-${key}.${file.name.split('.').pop()}`;

      // Upload file to the primary location
      const primaryStorageRef = ref(this.storage, primaryPath);
      const primaryUploadResult = await uploadBytes(primaryStorageRef, file);
      const primaryUrl = await getDownloadURL(primaryUploadResult.ref);
      //console.log(`File uploaded to primary location: ${primaryPath}`);
      //console.log(`Primary folder created for Tridy ID: ${tridyId}`);

      // Upload file to the secondary location
      const secondaryStorageRef = ref(this.storage, secondaryPath);
      const secondaryUploadResult = await uploadBytes(secondaryStorageRef, file);
      const secondaryUrl = await getDownloadURL(secondaryUploadResult.ref);
      //console.log(`File uploaded to secondary location: ${secondaryPath}`);
      //console.log(`Secondary folder created for Finished Task ID: ${finishedTaskId}`);

      // Return both file URLs
      return { primaryUrl, secondaryUrl };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  async createTridy(contextId: string, workTaskData: any, env:string): Promise<string> {
    const tridysCollection = collection(this.firestore, 'Tridys');

    // Filter the payload based on template's fields with 'payloadKey'
    const filteredPayload = Object.entries(workTaskData.payload || {}).reduce(
      (result, [key, value]) => {
        const fieldInTemplate = workTaskData.template?.formSections
          ?.flatMap((section: any) => section.fields)
          ?.find((field: any) => field.payloadKey === key);
        if (fieldInTemplate) {
          result[key] = value;
        }
        return result;
      },
      {} as Record<string, any>
    );

    if(env=="narravero-eu-dev"){
      this.tagHostName="nv2.at"
    }
    if(env=="narravero-eu-test"){
      this.tagHostName="nvero.app"
    }
    if(env=="narravero-eu-prod"){
      this.tagHostName="nvtest.eu"
    }

    const tridyData: any = {
      contentType: workTaskData.template.contentType,
      contextId: contextId,
      creationDate: serverTimestamp(),
      timeStamp: serverTimestamp(),
      'workTasks.payload': workTaskData.payload,
      'workTasks.tasks': [],
    };

    // Add payload only if filteredPayload has keys
    if (Object.keys(filteredPayload).length > 0) {
      tridyData.payload = filteredPayload;
    }

    // Create the Tridy document
    const tridyDocRef = await addDoc(tridysCollection, tridyData);
    const tridyId = tridyDocRef.id;

    // Update the Tridy document with the passURL and its ID
    const passURL = `https://${this.tagHostName}/${tridyId}`;
    await setDoc(tridyDocRef, { id: tridyId, passURL: passURL }, { merge: true });

    //console.log(`Tridy created with ID: ${tridyId} and passURL: ${passURL}`);

    // Create Identifiers for fields with 'identifierKey' and values
    const identifierFields = workTaskData.template?.formSections
    ?.flatMap((section: any) => section.fields)
    ?.filter((field: any) => field.identifierKey && filteredPayload[field.payloadKey]);

    if (identifierFields) {

      const identifiersCollection = collection(this.firestore, `${NetworkConstants.COLLECTION_CONTEXTS}/${contextId}/Identifiers`);

      for (const field of identifierFields) {
        const key = field.identifierKey;
        const value = filteredPayload[field.payloadKey];

        const identifierData = {
          creationDate: serverTimestamp(),
          timeStamp: serverTimestamp(),
          tridyId: tridyId,
          type: key,
          value: value,
        };

        const identifierDocRef = await addDoc(identifiersCollection, identifierData);
        //console.log(`Identifier created with ID: ${identifierDocRef.id}, type: ${key}, value: ${value}`);
      }
    }

    return tridyId;
  }

  async updateExistingTridy(tridyId: string, workTaskData: any): Promise<string> {

    const tridysCollection = collection(this.firestore, 'Tridys');

    // Filter the payload based on template's fields with 'payloadKey'
    const filteredPayload = Object.entries(workTaskData.payload || {}).reduce(
      (result, [key, value]) => {
        const fieldInTemplate = workTaskData.template?.formSections
          ?.flatMap((section: any) => section.fields)
          ?.find((field: any) => field.payloadKey === key);
        if (fieldInTemplate) {
          result[key] = value;
        }
        return result;
      },
      {} as Record<string, any>
    );

    const tridyData: any = {
      contentType: workTaskData.template.contentType,
      timeStamp: serverTimestamp(),
      'workTasks.payload': workTaskData.payload,
    };

    // Add payload only if filteredPayload has keys
    if (Object.keys(filteredPayload).length > 0) {
      tridyData.payload = filteredPayload;
    }

  // Reference to the document
  const tridyDocRef = doc(tridysCollection, tridyId);

  await runTransaction(this.firestore, async (transaction) => {
    const docSnapshot = await transaction.get(tridyDocRef);

    if (!docSnapshot.exists()) {
      throw new Error(`Tridy document with ID ${tridyId} does not exist.`);
    }

    const existingData = docSnapshot.data();
    const oldData = existingData['payload']

    // if tridyData has no field from existingData, add it but set as empty, and ignore object fields with contenttype
    for (const key of Object.keys(existingData?.['payload'] || {})) {
      if (!(key in tridyData.payload)) {
        if (typeof oldData[key] !== 'object' || oldData[key] === null) {
          tridyData.payload[key] = '';
        }
      }
    }

    // Update the document general and payload data
    transaction.set(
      tridyDocRef,
      {
        ...tridyData,
        'workTasks.payload': tridyData.payload,
      },
      { merge: true }
    );
  });

  return tridyId;
  }

  async saveWorkTask(contextId: string, workTaskData: any): Promise<string> {
    const tasksCollection = collection(this.firestore, `${NetworkConstants.COLLECTION_CONTEXTS}/${contextId}/WorkTasks`);
    const workTaskDocRef = await addDoc(tasksCollection, workTaskData);
    //console.log(`WorkTask created with ID: ${workTaskDocRef.id}`);
    return workTaskDocRef.id;
  }

  async updateExistingTridyWithWorkTask(
      tridyId: string,
      finishedTaskId: string,
      workTaskData: any
    ): Promise<void> {

    const tridyDocRef = doc(this.firestore, 'Tridys', tridyId);
    const docSnapshot = await getDoc(tridyDocRef);
    const existingData = docSnapshot.data();
    const existingPayload = existingData?.['workTasks.payload'] || {}; // this was updated in previous method 
    const existingTasks = existingData?.['workTasks.tasks'] || [];

    const workTaskReference = {
      id: finishedTaskId,
      payload: existingPayload, // experimental
      timeStamp: Timestamp.now(),
      userId: workTaskData.userId,
      visibleInPass: true,
      templateId: workTaskData.templateId,
    };
  
    const updatedTasks = [...existingTasks, workTaskReference];

    // Set the document with the updated tasks array
    await setDoc(
      tridyDocRef,
      {
        'workTasks.tasks': updatedTasks
      },
      { merge: true }
    );
    //console.log(`Updated workTasks with new task: ${finishedTaskId}`);

  }

  async updateNewTridyWithWorkTask(
    tridyId: string,
    finishedTaskId: string,
    workTaskData: any,
    payload: any
  ): Promise<void> {
    const tridyDocRef = doc(this.firestore, 'Tridys', tridyId);
  
    // Step 1: Create a cleaned payload for workTaskReference
    const cleanedPayload = Object.fromEntries(
      Object.entries(payload).map(([key, value]) => {
        if (
          value &&
          typeof value === 'object' &&
          'contentType' in value &&
          'id' in value &&
          'visibility' in value
        ) {
          const { contentType, id, payload: innerPayload, visibility, fileName } = value as {
            contentType: string;
            id: string;
            payload?: Record<string, any>;
            visibility: string;
            fileName?: string;
            primaryUrl?: string;
            secondaryUrl?: string;
          };
  
          return [
            key,
            {
              contentType,
              id,
              payload: innerPayload || {},
              visibility,
              ...(fileName && contentType !== 'public.image' ? { fileName } : {}), // Save fileName only if it's not an image
            },
          ];
        }
        return [key, value]; // Pass through non-file fields unchanged
      })
    );
  
    // Step 2: Extract media entries from payload
    const mediaEntries: any[] = Object.entries(payload)
    .filter(([_, value]) => value && typeof value === 'object' && 'primaryUrl' in value)
    .map(([key, value]) => {
      const fileValue = value as {
        primaryUrl: string;
        fileName?: string;
        contentType?: string;
        mimeType?: string;
      };
  
      const fileName =
        fileValue.fileName ||
        decodeURIComponent(fileValue.primaryUrl.split('/').pop()?.split('?')[0] || 'unknown.file');
      const fileUrl = `tridy://${encodeURIComponent(fileName)}`;
  
      const mimeType = fileValue.mimeType || null;
      return {
        count: 1,
        key,
        files: [
          {
            url: fileUrl,
            ...(mimeType ? { mimeType } : {}),
          },
        ],
      };
    });
  
  
    // Step 3: WorkTask reference
    const workTaskReference = {
      id: finishedTaskId,
      payload: cleanedPayload,
      timeStamp: Timestamp.now(),
      userId: workTaskData.userId,
      visibleInPass: true,
      templateId: workTaskData.templateId,
    };
  
    // Step 4: Update the Tridy
    await setDoc(
      tridyDocRef,
      {
        'workTasks.tasks': arrayUnion(workTaskReference),
        ...(mediaEntries.length > 0 ? { media: mediaEntries } : {}), // Include media if it exists
      },
      { merge: true }
    );
  
    //console.log(`Tridy updated with WorkTask ID: ${finishedTaskId} and media entries.`);
  }

// FINISHED TASKS DATA SERVICES

  getFinishedTasksData(contextId: string): Observable<any[]> {
    const templatesCollection = collection(
    this.firestore,
    `${NetworkConstants.COLLECTION_CONTEXTS}/${contextId}/WorkTasks`
    );
    return collectionData(templatesCollection, { idField: 'id' });
  } 

  setFinishedTasks(tasks: any[]): void {
    this.finishedTasks = tasks;
  }

  getFinishedTaskById(taskId: string): any {
    return this.finishedTasks.find((task) => task.id === taskId) || null;
  }

  selectFinishedTask(task: any): void {
    this.taskSubject.next(task);
  }

  getSelectedFinishedTask(): Observable<any> {
    return this.taskSubject.asObservable();
  }

  // LOCAL STORAGE DATA SERVICES

  // Save contextId to localStorage
  saveContextId(contextId: string): void {
    localStorage.setItem(this.CONTEXT_ID_KEY, contextId);
  }

  // Retrieve contextId from localStorage
  getLocalContextId(): string | null {
    return localStorage.getItem(this.CONTEXT_ID_KEY);
  }

  // Clear contextId from localStorage
  clearContextId(): void {
    localStorage.removeItem(this.CONTEXT_ID_KEY);
  }

}
