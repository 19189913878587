<mat-sidenav-container class="task-container">
  <mat-sidenav class="custom-sidenav" #sidenav fixedTopGap="70" fixedInViewport mode="side" opened>
    
    <div class="task-toolbar d-flex justify-content-between align-items-center">
      <mat-form-field appearance="fill" class="mx-3 w-100">
        <input
          [disabled]="disableSearch"
          matInput
          placeholder="Durchsuchen"
          [matTooltip]="!disableSearch ? 'Suche nach ID, Titel oder spezifischen Daten aus dem Payload.' : ''"
          [(ngModel)]="searchTerm"
          (input)="filterTemplates()" />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
<!--                 <div class="mb-4 mx-3">
        <button disabled mat-icon-button [matMenuTriggerFor]="filterMenu">
          <mat-icon>tune</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu">
          <button mat-menu-item>
            <mat-slide-toggle>Gruppen anzeigen</mat-slide-toggle>
          </button>
          filter for types (transform, edit, create)
          custom filter by
        </mat-menu>
      </div> -->
    </div>
    <div class="task-list">
      @if (finishedTasks.length > 0) {
        <mat-list style="margin-bottom: 30px">
         <!--  @for (template of workTaskTemplates; track template.id) { -->
         @for (task of filteredTemplates; track task.id) {
            <mat-list-item
              (click)="onSelectFinishedTask(task)"
              [class.active]="isFinishedTaskActive(task.id)"
              matTooltipShowDelay="1500"
              matTooltip="ID: {{ task.id }}"
            >
          <!-- <mat-list-item (click)="selectContext(context.id)"> -->
            <div class="d-flex w-100 justify-content-between align-items-center">
              <div>
                <span>{{ task.title }}</span>
                @if (task.payload?.['ddl-id']) {
                  <div class="ddl-info">DDL-ID: {{ task.payload?.['ddl-id'] }}</div>
                }
                <div class="time-details"><mat-icon>schedule</mat-icon> {{ task.timeStamp?.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}</div>
              </div>
              @if (isFinishedTaskActive(task.id)) {
                <mat-icon style="color:var(--warmOrange)">chevron_right</mat-icon>
              }
            </div>
          </mat-list-item>
          }
        </mat-list>
      }
      @else {
        <div class="h-100 align-items-center d-flex justify-content-center">
          <p>Nichts vorhanden.</p>
        </div>
      }
    </div>

      
  </mat-sidenav>
  <mat-sidenav-content>
    <app-finished-task-detail></app-finished-task-detail>
  </mat-sidenav-content>
</mat-sidenav-container>
