<mat-toolbar class="toolbar">
 
    <div class="logo">
        <img src="narravero_logo.webp" alt="Web Tracer Logo" class="logo-image">
    </div>

    <!-- <div class="sidebar-toggle">
        <button  mat-icon-button (click)="toggleSidebar.emit()">
            <mat-icon>menu</mat-icon>
        </button>
    </div> -->

<!--     <mat-form-field class="search-field">
        <mat-label>Clearable input</mat-label>
        <input matInput type="text" [(ngModel)]="value">
        @if (value) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
            </button>
        }
        <mat-icon matPrefix>search</mat-icon>
    </mat-form-field> -->


    <span class="spacer"></span>
    <button id="settings-button" disabled mat-icon-button aria-label="Settings">
        <mat-icon>settings</mat-icon>
    </button>
    <button id="notifications-button" disabled mat-icon-button aria-label="Notifications">
        <mat-icon>notifications</mat-icon>
    </button>
    <button id="user-button" mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="User profile">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu id="user-menu" #userMenu="matMenu">
        <button mat-menu-item disabled><mat-icon class="icon-margin">person</mat-icon> {{userDisplayName}}</button>
        <mat-divider></mat-divider>
        <button [disabled]="disableContextSwitch" id="change-account-button" matTooltipShowDelay="1200" matTooltip="Konto wechseln" mat-menu-item (click)="openContextDialog()">
            <mat-icon class="icon-margin">switch_account</mat-icon>
            <span *ngIf="contextData">{{ contextData.displayName }}</span>
            <span *ngIf="!contextData">Laden...</span>
        </button>
        <button id="logout-button" mat-menu-item (click)="logout()">
            <mat-icon class="icon-margin">exit_to_app</mat-icon> Abmelden</button>
    </mat-menu>
</mat-toolbar>
