<mat-sidenav-container class="form-container">
  <mat-sidenav class="custom-sidenav" #sidenav fixedTopGap="70" fixedInViewport mode="side" opened>
    
      <div class="task-toolbar d-flex justify-content-between align-items-center">
        <mat-form-field id="search-input" appearance="fill" class="mx-3 w-100">
          <input
            [disabled]="disableSearch"
            matInput
            placeholder="Durchsuchen"
            [(ngModel)]="searchTerm"
            (input)="filterTemplates()" />
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
<!--                 <div class="mb-4 mx-3">
          <button id="filter-button" disabled mat-icon-button [matMenuTriggerFor]="filterMenu">
            <mat-icon>tune</mat-icon>
          </button>
          <mat-menu #filterMenu="matMenu">
            <button mat-menu-item>
              <mat-slide-toggle>Gruppen anzeigen</mat-slide-toggle>
            </button>
             filter for types (transform, edit, create)
            custom filter by
          </mat-menu>
        </div> -->
      </div>
      <div class="template-list">
        @if (workTaskTemplates.length > 0) {
          <mat-list>
           <!--  @for (template of workTaskTemplates; track template.id) { -->
           @for (template of filteredTemplates; track template.id) {
              <mat-list-item
                (click)="onSelectTask(template)"
                [class.active]="isTaskActive(template.id)"
                matTooltipShowDelay="1500"
                matTooltip="ID: {{ template.id }}"
              >
            <!-- <mat-list-item (click)="selectContext(context.id)"> -->
              <div class="d-flex w-100 justify-content-between">
                <span>{{ template.title }}</span>
                @if (isTaskActive(template.id)) {
                  <mat-icon style="color:var(--warmOrange)">chevron_right</mat-icon>
                }
              </div>

            <!-- <span style="padding-left:5px; color:red">{{ template.taskType }}</span> -->
            </mat-list-item>
            }
          </mat-list>
        }
        @else {
          <div class="h-100 align-items-center d-flex justify-content-center">
            <p>Nichts vorhanden.</p>
          </div>
        }
      </div>

      
  </mat-sidenav>
  <mat-sidenav-content>
    <app-work-task-form></app-work-task-form>
  </mat-sidenav-content>
</mat-sidenav-container>